import { AttributeType } from 'features/print-methods/enums/attribute-type.enum';

export class UpsertPrintMethodAttributeRequest {
	id?: string;
	printMethodId: string;
	type: AttributeType;
	name: string;
	value: string;

	constructor(props: Partial<UpsertPrintMethodAttributeRequest>) {
		if (props.id) this.id = props.id;
		this.printMethodId = props.printMethodId || '';
		this.type = props.type || 1;
		this.name = props.name || '';
		this.value = props.value || '';
	}

	get getConvertUriExtension() {
		return 'upsert-print-method-attribute';
	}
}
