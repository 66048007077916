import { OrderItemDto } from 'models/dtos/order/order';
import styles from './ProductTable.module.scss';
import currencyHelper from 'helpers/curreny.helper';
import React from 'react';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import { Tooltip } from 'primereact/tooltip';
import toastHelper from 'helpers/toast.helper';
import { PrSwitchToggle } from 'helpers/widgets/Printram/Switch/PrSwitchToggle';

type Props = {
	orderItems: OrderItemDto[];
	canSelectableItems: boolean;
	selectedItemIds: string[];
	setSelectedItemIds: React.Dispatch<React.SetStateAction<string[]>>;
};

const ProductTable = (props: Partial<Props>) => {
	const getItemTotalPrice = (item: OrderItemDto) => {
		let price = item.price.formattedPricePerUnit;

		for (const side of item.sides) price += side.printPrice.formattedPricePerUnit + side.methodPrice.formattedPricePerUnit;

		return price;
	};

	return (
		<React.Fragment>
			<table className={styles.table}>
				<thead>
					<tr>
						<th>Products</th>
						<th>Print File</th>
						<th>Personalization</th>
						<th>Seller Note</th>
						<th>Total</th>
					</tr>
				</thead>

				<tbody>
					{props.orderItems?.map((_item, index) => (
						<tr key={index}>
							<td>
								<div className={styles.productInfo}>
									<div className={styles.productImgContainer}>
										<img src={amazonS3Helper.showFile(_item.variantMedia, { resize: '100x100' })} alt={_item.coreProductName} width={80} />
									</div>

									<div className={styles.productInfoContainer}>
										<h6>{_item.coreProductName}</h6>

										<span
											className="cursor-pointer"
											onClick={() => {
												navigator.clipboard.writeText(_item.code);
												toastHelper.success(`Copied '${_item.code}' to clipboard`);
											}}>
											Item: #{_item.code}
										</span>

										<span>
											Model: {_item.brandName} {_item.modelName}
										</span>

										<span>Size: {_item.size.name}</span>

										<span className={styles.productInfoContainerColor}>
											<Tooltip target={`#color-${index}-${_item.color.id}`} />
											Color:
											<span id={`color-${index}-${_item.color.id}`} data-pr-tooltip={_item.color.hexCode} data-pr-position="top" style={{ backgroundColor: _item.color.hexCode }} />
											{_item.color.name}
										</span>
									</div>
								</div>

								{props.canSelectableItems && props.selectedItemIds && props.setSelectedItemIds ? (
									<div className="flex gap-2 align-items-center mt-3">
										<PrSwitchToggle
											checked={props.selectedItemIds?.findIndex((_selectedItemId) => _selectedItemId === _item.id) > -1}
											onChange={(e) => {
												props.setSelectedItemIds!((_selectedItems) => (e ? [..._selectedItems, _item.id] : _selectedItems!.filter((_id) => _id !== _item.id)));
											}}
										/>

										<span>Select{props.selectedItemIds?.findIndex((_selectedItemId) => _selectedItemId === _item.id) > -1 ? 'ed' : ''}</span>
									</div>
								) : null}
							</td>

							<td>
								<div className={styles.printFileWrapper}>
									{_item.sides.map((_side, sideIndex) => (
										<React.Fragment key={sideIndex}>
											<div className={styles.printFileItem}>
												<div className={styles.printFileItemImgContainer}>
													<img src={amazonS3Helper.showFile(_side.design, { resize: '100x100' })} width={100} alt={`${_side.printSideName} Design`} style={{ filter: 'drop-shadow(0 0 0.4px #AEAEAE)' }} />
												</div>

												<div className={styles.printFileItemInfo}>
													<span>{_side.printSideName} Side Print File</span>
												</div>

												<div className={styles.printFileItemInfo}>
													<span>{_side.printMethodName}</span>
												</div>
											</div>

											<div className={styles.printFileItem}>
												<div className={styles.printFileItemImgContainer}>
													<img src={amazonS3Helper.showFile(_side.templateMockup, { resize: '100x100' })} width={100} alt={`${_side.printSideName} Mockup`} />
												</div>

												<div className={styles.printFileItemInfo}>
													<span>{_side.printSideName} Side Mockup</span>
												</div>
											</div>
										</React.Fragment>
									))}
								</div>
							</td>

							<td>{_item.personalization || '-'}</td>

							<td>{_item.sellerNote || '-'}</td>

							<td>{currencyHelper.formatPrice(getItemTotalPrice(_item))}</td>
						</tr>
					))}
				</tbody>
			</table>
		</React.Fragment>
	);
};

export default ProductTable;
