import { DeliveryTypes } from '../../orders/_common/create-manuel-order/create-manuel-order.request';
import { GetOrdersDateFilterRequestDto } from '../../orders/_common/get-orders-for-seller-new/get-orders-for-seller-new.request';
import { PaginationDto } from '../../_common/dtos/paginations/pagination.dto';

export class GetCustomDtfOrdersRequest {
	sellerId: string;
	contains: string;
	orderStatu: CustomDtfOrderStatu | null;
	deliveryType: DeliveryTypes | null;
	dateFilter: GetOrdersDateFilterRequestDto | null;
	pagination: PaginationDto | null;

	constructor(props: Partial<GetCustomDtfOrdersRequest>) {
		this.sellerId = props.sellerId || '';
		this.contains = props.contains || '';
		this.orderStatu = props.orderStatu || null;
		this.deliveryType = props.deliveryType || null;
		this.dateFilter = props.dateFilter || new GetOrdersDateFilterRequestDto({});
		this.pagination = props.pagination || new PaginationDto(1, 20);;
	}

	get getConvertUriExtension() {
		return 'get-orders-by-seller-id';
	}
}

export enum CustomDtfOrderStatu {
	Unknown = 0,
	OrderApproved = 1,
	Printing = 2,
	Printed = 3,
	DeliveredToCarrier = 4,
	ReadyForPickup = 5,
	Cancelled = 6,
}