import { fabric } from 'fabric';
import { Color } from 'features/products/_common/get-core-product/get-core-product.response';
import colorHelper from 'helpers/color.helper';
import htmlToImageHelper from 'helpers/html-to-image.helper';
import React, { useEffect, useRef } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { SideDesignDialog } from '../../SideWithDesignDialog';
import styles from './Preview.module.scss';

export interface FabricJson {
	version: string;
	objects: fabric.Object[];
}

export interface PreviewOptions {
	// canvasRealMinSizeInch: number;
	// canvasMinSizePx: number;
	canvasHeightSizePx: number;
	canvasWidthSizePx: number;
	coefficient: number;
}

type Props = {
	side: SideDesignDialog;
	setSides: React.Dispatch<React.SetStateAction<SideDesignDialog[]>>;
	color: Color;
	isActive: boolean;
	buildMockup: boolean;
	setLoadings: React.Dispatch<React.SetStateAction<boolean[]>>;
	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
};

const initCanvas = (id: string, options?: fabric.ICanvasOptions | undefined): fabric.Canvas => new fabric.Canvas(id, options);

export const Preview = (props: Props) => {
	const divRef = useRef<HTMLDivElement>(null);

	const [canvas, setCanvas] = useStateWithCallbackLazy<fabric.Canvas | undefined>(undefined);
	const [options, setOptions] = useStateWithCallbackLazy<PreviewOptions | undefined>(undefined);

	const setSidePrintOptions = (size: { width: number; height: number; isClearNewUploadedFile?: boolean }) => {
		props.setSides((_sides) =>
			_sides.map((_side) => {
				if (_side.id !== props.side.id) return _side;

				const newSide: SideDesignDialog = structuredClone(_side);

				if (!newSide.printInfo) return _side;

				newSide.printInfo.printWidth = size.width > newSide.printInfo.maxPrintWidth ? newSide.printInfo.maxPrintWidth : size.width;
				newSide.printInfo.printHeight = size.height > newSide.printInfo.maxPrintHeight ? newSide.printInfo.maxPrintHeight : size.height;
				newSide.isNewUploadFile = size.isClearNewUploadedFile;

				return newSide;
			})
		);
	};

	const setSideJson = (fabricJson: FabricJson) => {
		props.setSides((_sides) =>
			_sides.map((_side) => {
				if (_side.id !== props.side.id) return _side;

				_side.fabricJson = fabricJson;

				return _side;
			})
		);
	};

	const setChangeSize = () => {
		props.setSides((_sides) =>
			_sides.map((_side) => {
				if (_side.id !== props.side.id) return _side;

				_side.isChangeSize = false;

				return _side;
			})
		);
	};

	const setSideIsEdited = () => {
		props.setSides((_sides) =>
			_sides.map((_side) => {
				if (_side.id !== props.side.id) return _side;

				_side.isEditSide = undefined;

				return _side;
			})
		);
	};

	const createBuildMockup = (mockupBase64: string) => {
		props.setSides((_sides) =>
			_sides.map((_side) => {
				if (_side.id !== props.side.id) return _side;

				_side.mockupBase64 = mockupBase64;

				return _side;
			})
		);
	};

	const createAndBuildMockup = async () => {
		try {
			props.setLoadings((_loadings) => [..._loadings, true]);
			if (!divRef.current || !canvas?.getObjects() || canvas.getObjects().length < 1) throw '';

			canvas.discardActiveObject();
			canvas.renderAll();

			divRef.current.style.display = 'block';
			divRef.current.style.backgroundColor = `${props.color?.hexCode}`;
			divRef.current.style.backgroundImage = ` url('${props.side?.sideImageBase64}'), url('${props.color.imageUrl}')`;

			const base64 = await htmlToImageHelper.toPng(divRef.current);
			if (base64.length < 15) throw '';

			divRef.current.style.display = props.isActive ? 'block' : 'none';
			createBuildMockup(base64);
		} catch (error) {
		} finally {
			props.setLoadings((_loadings) => {
				_loadings.pop();

				return _loadings;
			});
		}
	};

	const clearSideFiles = () => {
		props.setSides((_sides) =>
			_sides.map((_side) => {
				if (_side.id !== props.side.id) return _side;

				const newSide: SideDesignDialog = structuredClone(_side);

				newSide.fabricJson = undefined;
				newSide.designFile = undefined;
				newSide.mockupBase64 = undefined;

				return newSide;
			})
		);
		props.setSelectedTab(1);
	};

	const handleDeleteOnKeyDownEvent = (event: KeyboardEvent) => {
		if ((event.code === 'Delete' || event.code === 'Backspace') && !!canvas?.getActiveObjects() && canvas.getActiveObjects().length > 0) {
			clearSideFiles();

			canvas?.clear();
		}
	};

	const handleObjectModifiedEvent = (event: fabric.IEvent<Event>) => {
		const object = event.target;

		if (!object || !props.side || !props.side.printInfo || event.action !== 'scale' || !options || !canvas) return;

		let objectWidth = object.getScaledWidth() || 0;
		let objectHeight = object.getScaledHeight() || 0;

		const maxPrintWidth = props.side.printInfo.maxPrintWidth;
		const maxPrintHeight = props.side.printInfo.maxPrintHeight;

		if (objectWidth > props.side.area.width && objectWidth > objectHeight) {
			!object.angle && object.set({ top: 0, left: 0 });
			object.scaleToWidth((maxPrintWidth * 300) / options.coefficient);

			canvas.renderAll();
		} else if (objectHeight > props.side.area.height) {
			!object.angle && object.set({ top: 0, left: 0 });
			object.scaleToHeight((maxPrintHeight * 300) / options.coefficient);

			canvas.renderAll();
		}

		objectWidth = object.getScaledWidth() || 0;
		objectHeight = object.getScaledHeight() || 0;

		const resultWidth = (objectWidth * options.coefficient) / 300;
		const resultHeight = (objectHeight * options.coefficient) / 300;

		const multiplier = Math.pow(10, 2);

		setSidePrintOptions({
			width: Math.round(resultWidth * multiplier) / multiplier,
			height: Math.round(resultHeight * multiplier) / multiplier
		});

		canvas.renderAll();
		setSideJson(canvas.toJSON());
	};

	const handleFileChange = (designFile: File) => {
		const newImageObj = new Image();

		newImageObj.src = URL.createObjectURL(designFile);

		newImageObj.onload = () => {
			if (!props.side.printInfo || !options || !canvas) return;

			const fabricImage = new fabric.Image(newImageObj);

			const imageWidth = fabricImage.width || 0;
			const imageHeight = fabricImage.height || 0;

			fabricImage.scale(Math.min(options.canvasWidthSizePx / imageWidth, options.canvasHeightSizePx / imageHeight));

			canvas.clear();

			fabricImage.set({
				left: (canvas.getWidth() - fabricImage.getScaledWidth()) / 2
				// top: (canvas.getHeight() - fabricImage.getScaledHeight()) / 2
			});

			canvas.add(fabricImage);

			fabricImage.setControlsVisibility({
				mt: false,
				mr: false,
				mb: false,
				ml: false
			});

			canvas.renderAll();
			setSideJson(canvas.toJSON());

			setSidePrintOptions({
				width: +((fabricImage.getScaledWidth() * options.coefficient) / 300).toFixed(2),
				height: +((fabricImage.getScaledHeight() * options.coefficient) / 300).toFixed(2)
			});
		};
	};

	const calculateAndSetCoffience = (callback: (state: PreviewOptions | undefined) => void) => {
		if (!props.side.printInfo) return;

		// const canvasRealMinSizeInch = props.side.printInfo.maxPrintWidth > props.side.printInfo.maxPrintHeight ? props.side.printInfo.maxPrintHeight : props.side.printInfo.maxPrintWidth;
		// const canvasMinSizePx = props.side.area.width > props.side.area.height ? props.side.area.height : props.side.area.width;

		const canvasHeightSizePx = props.side.area.height;
		const canvasWidthSizePx = props.side.area.width;

		const canvasHeightSizeInch = props.side.printInfo.maxPrintHeight;
		const canvasWidthSizeInch = props.side.printInfo.maxPrintWidth;

		setOptions(
			{
				// canvasRealMinSizeInch,
				// canvasMinSizePx,
				//coefficient: (canvasRealMinSizeInch * 300) / canvasMinSizePx
				coefficient: Math.min((canvasHeightSizeInch * 300) / canvasHeightSizePx, (canvasWidthSizeInch * 300) / canvasWidthSizePx),
				canvasHeightSizePx,
				canvasWidthSizePx
			},
			callback
		);
	};

	const handleResizeFile = (size: number, sizeType: 'width' | 'height') => {
		if (!canvas?.getObjects() || canvas.getObjects().length < 1 || !options) return;

		const object = canvas.getObjects()[0];

		sizeType === 'width' ? object.scaleToWidth((size * 300) / options.coefficient) : object.scaleToHeight((size * 300) / options.coefficient);

		!object.angle &&
			object.set({
				top: 0,
				left: (canvas.getWidth() - object.getScaledWidth()) / 2
			});

		canvas.renderAll();

		const resultWidth = (object.getBoundingRect().width * options.coefficient) / 300;
		const resultHeight = (object.getBoundingRect().height * options.coefficient) / 300;

		const multiplier = Math.pow(10, 2);

		setSidePrintOptions({
			width: Math.round(resultWidth * multiplier) / multiplier,
			height: Math.round(resultHeight * multiplier) / multiplier
		});
	};

	useEffect(() => {
		if (!!divRef.current) {
			setCanvas(initCanvas(`fabriccanvas${props.side.id}`, { width: props.side.area.width, height: props.side.area.height }), () => {
				calculateAndSetCoffience(() => {});
			});
		}

		return () => {
			canvas?.dispose();
		};
	}, []);

	useEffect(() => {
		if (!!canvas && !!props.side.isChangeSize) {
			calculateAndSetCoffience(() => {});

			handleResizeFile(props.side.printInfo?.printWidth || 0, 'width');

			setChangeSize();
		}
	}, [props.side.isChangeSize]);

	useEffect(() => {
		if (!!canvas && !!options && props.side.designFile && !props.side.fabricJson) handleFileChange(props.side.designFile);
		else if (!!canvas && !!options && props.side.designFile && !!props.side.fabricJson && !!props.side.isEditSide) {
			canvas.loadFromJSON(props.side.fabricJson, () => {
				setSideIsEdited();
			});
		}
	}, [options?.coefficient]);

	useEffect(() => {
		if (!!canvas) {
			if (!!props.side?.designFile && props.side.isNewUploadFile) handleFileChange(props.side.designFile);
			else if (!props.side?.designFile && !props.side.isNewUploadFile) canvas.clear();
		}
	}, [props.side.designFile, props.side.isNewUploadFile]);

	useEffect(() => {
		if (!!canvas && !!options && typeof props.side.isChangeSize === 'undefined') {
			canvas?.on('object:modified', handleObjectModifiedEvent);
			document.addEventListener('keydown', handleDeleteOnKeyDownEvent);
		}
	}, [canvas, options]);

	useEffect(() => {
		if (divRef.current && props.buildMockup) createAndBuildMockup();
	}, [divRef.current, props.buildMockup]);

	useEffect(() => {
		if (!!props.side.designFile && props.side.printInfo?.printWidth && !props.side.isChangeSize) handleResizeFile(props.side.printInfo.printWidth, 'width');
	}, [props.side.printInfo?.printWidth]);

	useEffect(() => {
		if (!!props.side.designFile && props.side.printInfo?.printHeight && !props.side.isChangeSize) handleResizeFile(props.side.printInfo.printHeight, 'height');
	}, [props.side.printInfo?.printHeight]);

	return (
		<div
			ref={divRef}
			style={
				{
					display: props.isActive ? 'block' : 'none',
					position: 'relative',
					backgroundColor: `${props.color?.hexCode}`,
					backgroundImage: ` url('${props.side?.sideImageBase64}'), url('${props.color.imageUrl}')`,
					width: `${props.side?.medias[0].mediaWidth.toFixed()}px`,
					height: `${props.side?.medias[0].mediaHeight.toFixed()}px`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					'--border-color': colorHelper.getContrast(props.color?.hexCode!),
					'--area-y': `${props.side?.area.yCoordinate.toFixed()}px`,
					'--area-x': `${props.side?.area.xCoordinate.toFixed()}px`
				} as React.CSSProperties
			}>
			<canvas id={`fabriccanvas${props.side.id}`} className={styles.canvas}></canvas>
		</div>
	);
};
