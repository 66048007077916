import { User } from '../models/dtos/auth/users/user';
import home from '../assets/icons/category.svg';
import shirt from '../assets/icons/shirt.svg';
import order from '../assets/icons/truck2.svg';
import reOrder from '../assets/icons/truck.svg';
import send from '../assets/icons/send.svg';
import store from '../assets/icons/shop.svg';
import medal from '../assets/icons/medal-star.svg';
import payment from '../assets/icons/cards.svg';
import layer from '../assets/icons/layer.svg';
import tag from '../assets/icons/tag.svg';
import star from '../assets/icons/star.svg';
import document from '../assets/icons/document.svg';
import elementEqual from '../assets/icons/element-equal.svg';
import box from '../assets/icons/box.svg';
import folder from '../assets/icons/folder.svg';
import brush from '../assets/icons/brush.svg';
import maximize1 from '../assets/icons/maximize-3.svg';
import maximize from '../assets/icons/maximize.svg';
import videoAdd from '../assets/icons/video-add.svg';
import map from '../assets/icons/map.svg';
import laptop from '../assets/icons/laptop.svg';
import menuBurger from '../assets/icons/menu-burger.svg';
import boxSearch from '../assets/icons/box-search.svg';
import chart from '../assets/icons/chart.svg';
import people from '../assets/icons/people.svg';
import link from '../assets/icons/link.svg';
import building from '../assets/icons/building.svg';
import directBoxDefault from '../assets/icons/directbox-default.svg';
import directBoxRecieve from '../assets/icons/directbox-receive.svg';
import video from '../assets/icons/video.svg';
import userOctagon from '../assets/icons/user-octagon.svg';
import profileUser from '../assets/icons/profile-2user.svg';
import dtf from '../assets/icons/dtf.svg';

const getMenuByRoles = (user: User | undefined) => {
	if (user?.roles.isPackager)
		return [
			{
				label: 'Main',
				icon: 'pi pi-fw pi-home',
				items: [
					//{ label: 'Barcode Operations', icon: 'pi pi-fw pi-align-justify', to: '/' },
					{ label: 'Barcode Operations', icon: 'pi pi-fw pi-align-justify', to: '/' },
					{ label: 'Barcode Operations (Beta)', icon: 'pi pi-fw pi-align-justify', to: '/beta/' }
				]
			}
		];

	if (user?.roles.isDtfManager)
		return [
			{
				label: 'Main',
				icon: home,
				items: [{ label: 'Dtf Lists', icon: 'pi pi-fw pi-print', to: '/' }]
			}
		];

	const dashboardMenu = {
		label: 'Main',
		icon: home,
		items: [{ label: 'Dashboard', icon: home, to: '/' }]
	};

	const sellerMenu = [
		{ ...dashboardMenu },
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Product Catalog', icon: shirt, to: '/product/catalog' },
				{ label: 'Orders', icon: order, to: '/product/seller/orders?status=2' },
				{ label: 'Re Orders', icon: reOrder, to: '/seller/re-orders' },
				{ label: 'Custom DTF', icon: directBoxDefault, to: '/custom-dtf' },
				{ label: 'Tickets', icon: send, to: '/tickets' },
				{ label: 'My Stores', icon: store, to: '/account/my-stores' },
				{ label: 'Printram Premium', icon: medal, to: '/account/plans' },
				{ label: 'Payments', icon: payment, to: '/account/payments' }
			]
		}
	];

	sellerMenu.map((menu) => {
		if (menu.label !== 'Pages') return menu;

		if (user?.subscription?.planAndHelper?.helper.isHaveRecordedTraining) menu.items.push({ label: 'Resources', icon: 'pi pi-video', to: '/resources' });
	});

	const merchantMenu = [
		{ ...dashboardMenu },
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Product Catalog', icon: shirt, to: '/product/catalog' },
				{ label: 'Tickets', icon: send, to: '/tickets' },
				{ label: 'My Products', icon: shirt, to: '/product/merchant/list' }
			]
		}
	];

	const printCenterMenu = [
		{ ...dashboardMenu },
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Product Catalog', icon: shirt, to: '/product/catalog' },
				{ label: 'Orders', icon: order, to: 'product/print-center/orders' },
				{ label: 'My Stocks', icon: tag, to: 'product/print-center/stocks' },
				{ label: 'Stock Management', icon: layer, to: 'product/print-center/stock-management' },
				{ label: 'Assigned Stores', icon: store, to: '/assigned-stores' },
				{ label: 'Tickets', icon: send, to: '/tickets' }
			]
		}
	];

	const adminMenu = [
		{ ...dashboardMenu },
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-fill',
			items: [{ label: 'Product Catalog', icon: shirt, to: '/product/catalog' }]
		},
		{
			label: 'Product Management',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Products', icon: shirt, to: '/products' },
				{ label: 'Prices', icon: tag, to: '/product-prices' },
				{ label: 'Brands', icon: star, to: '/brands' },
				{ label: 'Models', icon: document, to: '/models' },
				{ label: 'Categories', icon: elementEqual, to: '/categories' },
				{ label: 'Template Products', icon: box, to: '/template-products' },
				{ label: 'Template Categories', icon: folder, to: '/template-categories' },
				{ label: 'Colors', icon: brush, to: '/colors' },
				{ label: 'Sizes', icon: maximize1, to: '/sizes' },
				{ label: 'Assigned Products', icon: maximize, to: '/assigned-products' }
			]
		},
		{
			label: 'Stock Management',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Printram Stocks', icon: videoAdd, to: '/stocks/printram' },
				{ label: 'Print Center Stocks', icon: videoAdd, to: '/stocks/print-center' },
				{ label: 'Stock Management', icon: layer, to: '/stocks/stock-management' },
				{ label: 'Locations', icon: map, to: '/locations' }
			]
		},
		{
			label: 'Order Management',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Orders', icon: order, to: '/orders?status=3' },
				{ label: 'Re Orders', icon: reOrder, to: '/re-orders' },
				{ label: 'Dtf Lists', icon: laptop, to: '/dtfs' },
				{ label: 'Custom DTF', icon: directBoxDefault, to: '/custom-dtf' },
				// { label: 'Barcode Scan', icon: 'pi pi-fw pi-qrcode', to: '/barcode-scan' },
				{ label: 'Barcode Operations', icon: menuBurger, to: '/barcodes/' },
				{ label: 'Barcode Operations (Beta)', icon: menuBurger, to: '/barcodes-beta/' },
				{ label: 'Operators', icon: userOctagon, to: '/operators' },
				{ label: 'Shipping Informations', icon: boxSearch, to: '/shipping-informations' },
				{ label: 'Custom DTF', icon: dtf, to: '/custom-dtf' }
			]
		},
		{
			label: 'User Management',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Surveys', icon: chart, to: '/surveys' },
				{ label: 'Sellers', icon: people, to: '/sellers' },
				{ label: 'Seller Stores', icon: store, to: '/seller-stores' },
				{ label: 'Seller Groups', icon: profileUser, to: '/seller-groups' },
				{ label: 'Etsy Connections', icon: link, to: '/etsy-connections' },
				{ label: 'Merchants', icon: building, to: '/merchants' },
				{ label: 'Print Centers', icon: directBoxDefault, to: '/print-centers' },
				{ label: 'Tickets', icon: send, to: '/tickets' },
				{ label: 'Reorder Tickets', icon: send, to: '/re-order-tickets' },
				{ label: 'Contacts', icon: directBoxRecieve, to: '/contacts' },
				{ label: 'Resources', icon: video, to: '/resources' },
				{ label: 'Plans', icon: medal, to: '/plans' }
			]
		},
		{
			label: 'Get In Touch Requests',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Get In Touch Forms', icon: send, to: '/forms/get-in-touch-forms' },
				{ label: 'Boost Potential Forms', icon: send, to: '/forms/boost-potential-forms' }
			]
		}
	];

	const menu = user?.roles.isAdmin ? adminMenu : user?.roles.isSeller ? sellerMenu : user?.roles.isMerchant ? merchantMenu : printCenterMenu;

	return [
		...menu
		/*{
			label: 'Help',
			icon: 'pi pi-fw pi-home',
			items: [
				{
					label: 'Free Mockups & Designs',
					icon: 'pi pi-fw pi-external-link',
					command: () => {
						const aElement = document.createElement('a');
						aElement.href = 'https://drive.google.com/drive/folders/1UtDhe69NbsVuXyMZJAfZB2_ARVl80cnL';
						aElement.target = '_blank';
						aElement.click();
						aElement.remove();
					}
				},
				{
					label: 'FAQ - Printram (TR)',
					icon: 'pi pi-fw pi-external-link',
					command: () => {
						const aElement = document.createElement('a');
						aElement.href = 'https://printram.blob.core.windows.net/important/FAQ-PRINTRAM.pdf';
						aElement.target = '_blank';
						aElement.click();
						aElement.remove();
					}
				},
				{
					label: 'FAQ - Etsy (TR)',
					icon: 'pi pi-fw pi-external-link',
					command: () => {
						const aElement = document.createElement('a');
						aElement.href = 'https://printram.blob.core.windows.net/important/FAQ-ETSY.pdf';
						aElement.target = '_blank';
						aElement.click();
						aElement.remove();
					}
				},
				{
					label: 'How to open a store on ETSY? (TR)',
					icon: 'pi pi-fw pi-external-link',
					command: () => {
						const aElement = document.createElement('a');
						aElement.href = 'https://printram.blob.core.windows.net/important/EtsyGuide.pdf';
						aElement.target = '_blank';
						aElement.click();
						aElement.remove();
					}
				}
			]
		}*/
	];
};

const menuHelper = { getMenuByRoles };

export default menuHelper;
