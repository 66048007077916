import { SyncEtsyProduct } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/dtos/sync-etsy-product.dto';
import { ConvertImageToBase64Request } from 'features/materials/convert-image-to-base64/convert-image-to-base64.request';
import { materialService } from 'features/materials/material.service';
import { AvailablePrintMethod, CoreProductSynchronizeDto } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import { Color, Size, Variant } from 'features/products/_common/get-core-product/get-core-product.response';
import { ProductPrintSide, ProductPrintSideMedia } from 'features/products/derived-features/product-print-sides/get-product-print-sides/get-product-print-sides.response';
import { CreateOrUpdateUserPrintSideDimensionRequest } from 'features/users/derived_features/user-print-side-dimensions/create-or-update-user-print-side-dimension/create-or-update-user-print-side-dimension.request';
import { userPrintSideDimensionService } from 'features/users/derived_features/user-print-side-dimensions/user-print-side-dimension.service';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import { acceptedFileExtensions } from 'helpers/constants/accepted-file-extensions';
import currencyHelper from 'helpers/curreny.helper';
import { fileHelper } from 'helpers/file.helper';
import toastHelper from 'helpers/toast.helper';
import { variantHelper } from 'helpers/variant.helper';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { FaChevronRight, FaChevronLeft, FaCog } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { useAppSelector } from 'redux/hooks';
import { v4 as UUID } from 'uuid';
import { DefaultPrintOptionsDialog } from '../default-print-options/DefaultPrintOptionsDialog';
import styles from './SideWithDesignDialog.module.scss';
import { FabricJson, Preview } from './components/new/Preview';
import { CiCircleInfo } from 'react-icons/ci';
import { Base64Response } from '../../../../features/materials/convert-image-to-base64/convert-image-to-base64.response';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import Cookies from 'js-cookie';
import StartTutorialTourButton from 'helpers/widgets/Tutorial/StartTutorialTourButton';
import { PiTShirtThin } from 'react-icons/pi';
import { GiWireCoil } from 'react-icons/gi';
import { GetEmbroideryImageRequest } from 'features/print-methods/_common/get-embroidery-image/get-embroidery-image.request';
import printMethodsService from 'features/print-methods/_common/print-methods.service';
import { FaArrowRight } from 'react-icons/fa6';
import { GetMethodsAttributeByTypeRequest } from 'features/print-methods/_common/get-method-attribute-by-type/get-method-attribute-by-type.request';
import PrColorPicker, { PrColorPickerType } from 'helpers/widgets/Printram/ColorPicker/PrColorPicker';

export interface SidePrintInfo {
	sideId: string;
	sizeId: string;
	printWidth: number;
	printHeight: number;
	minPrintWidth: number;
	minPrintHeight: number;
	maxPrintWidth: number;
	maxPrintHeight: number;
}

export interface PrintMethodAttributeDto {
	printMethodAttributeId: string;
	attributeValue: string;
}

export type SideDesignDialog = ProductPrintSide &
	Partial<{
		sideImageBase64: string;
		mockupBase64: string;
		isEditSide: boolean;
		isNewUploadFile: boolean;
		designFile: File;
		fabricJson: FabricJson;
		printInfo: SidePrintInfo;
		isChangeSize: boolean;
		productPrintSideMethodId: string;
		productPrintSideMethodName: string;
		printMethodAttributes?: PrintMethodAttributeDto[];
		selectedPrintMethod: AvailablePrintMethod;
		oldDesignFile: File;
	}>;

export interface ConfirmSideWithDesign {
	uniqueId: string;
	sides: SideDesignDialog[];
	product: CoreProductSynchronizeDto;
	size: Size;
	color: Color;
	variant: Variant;
}

type Props = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	product: CoreProductSynchronizeDto;
	editProduct: ConfirmSideWithDesign;
	onChangeProduct: () => void;
	onConfirm: (event: ConfirmSideWithDesign) => void;
	setSyncProduct: React.Dispatch<React.SetStateAction<SyncEtsyProduct | undefined>>;
	syncProduct: SyncEtsyProduct;
	color: Color;
	size: Size;
};

export const SideWithDesignDialog = (props: Partial<Props>) => {
	const user = useAppSelector((_state) => _state.auth.data?.user);

	const sidesContainerRef = useRef<HTMLDivElement>(null);

	const [buildMockup, setBuildMockup] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadings, setLoadings] = useState<boolean[]>([]);
	const [loadingText, setLoadingText] = useState<string>('Loading');
	const [product, setProduct] = useState<CoreProductSynchronizeDto | undefined>(undefined);
	const [selectedSize, setSelectedSize] = useState<Size | undefined>(undefined);
	const [selectedColor, setSelectedColor] = useState<Color | undefined>(undefined);
	const [defaultColor, setDefaultColor] = useState<Color | undefined>(undefined);
	const [methodAttributes, setMethodAttributes] = useState<PrColorPickerType[]>([]);
	const [allSides, setAllSides] = useState<SideDesignDialog[]>([]);
	const [sides, setSides] = useState<SideDesignDialog[]>([]);
	const [selectedSideId, setSelectedSideId] = useState<string | undefined>(undefined);
	const [printSettingsVisible, setPrintSettingsVisible] = useState<boolean>(false);
	const [showLeftBtn, setShowLeftBtn] = useState<boolean>(false);
	const [showRightBtn, setShowRightBtn] = useState<boolean>(false);
	const [selectedPrintMethod, setSelectedPrintMethod] = useState<AvailablePrintMethod | undefined>(undefined);
	const [selectedTab, setSelectedTab] = useState<number>(1);
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false);
	const [tutorialStepsEnabled2, setTutorialStepsEnabled2] = useState<boolean>(false);

	const tabs = [
		{ icon: <PiTShirtThin size={32} />, label: 'Product', value: 1 },
		{ icon: <GiWireCoil size={32} />, label: 'Thread Colors', value: 2 }
	];

	//#Tutorial Steps
	const steps1 = [
		{
			element: '.tutorial-selector0',
			intro: 'Welcome to the Printram product selection!'
		},
		{
			element: '.tutorial-selector1',
			intro: 'Choose the color you want for your order from this section.'
		},
		{
			element: '.tutorial-selector2',
			intro: 'Select the size you want for your order from this section.'
		}
	];

	const steps2 = [
		{
			element: '.tutorial-selector3',
			intro: 'You can change the side you want to print on from this section.'
		},
		{
			element: '.tutorial-selector4',
			intro: "You can upload the design you want to print by either dragging and dropping it here or selecting it through your computer's file manager"
		}
	];

	const clearStates = () => {
		setLoading(false);
		setPrintSettingsVisible(false);
		setSides([]);
		setAllSides([]);
		setSelectedTab(1);
		setSelectedPrintMethod(undefined);
		setProduct(undefined);
		setSelectedSideId(undefined);
		setSelectedColor(undefined);
		setSelectedSize(undefined);
		setDefaultColor(undefined);
		setBuildMockup(false);
		setLoadings([]);
	};

	const handleOnClose = () => {
		if (props.visible) props.setVisible?.(false);

		props.setSyncProduct?.(undefined);
		Cookies.set('product-select-intro-1', 'true');
		Cookies.set('product-select-intro-2', 'true');
		clearStates();
	};

	const getProductPrintInfo = (size: Size, side: ProductPrintSide): SidePrintInfo | undefined => {
		const userDimension = side.userPrintDimensions.find((_dimension) => _dimension.size.id === size.id);
		const sideDimension = side.printDimensions.find((_dimension) => _dimension.size.id === size.id);

		if (!sideDimension && !userDimension) return;

		return {
			sizeId: size.id,
			sideId: side.id,
			printWidth: userDimension?.defaultPrintSizeWidth || sideDimension?.maxPrintSizeWidth || 0,
			printHeight: userDimension?.defaultPrintSizeHeight || sideDimension?.maxPrintSizeHeight || 0,
			minPrintWidth: sideDimension?.minPrintSizeWidth || 0,
			minPrintHeight: sideDimension?.minPrintSizeHeight || 0,
			maxPrintWidth: sideDimension?.maxPrintSizeWidth || 0,
			maxPrintHeight: sideDimension?.maxPrintSizeHeight || 0
		};
	};

	const getImageUrl = async (imageUrls: string[]) => {
		if (imageUrls.length < 1) return;

		try {
			const response = await materialService.convertImageToBase64(new ConvertImageToBase64Request(imageUrls));
			if (!response.isSuccess || !response.data) throw '';

			return response.data;
		} catch (error) {
			toastHelper.error('Few image can not converted');
		}
	};

	const getPrintMethodAttributes = async () => {
		try {
			const request = new GetMethodsAttributeByTypeRequest({ printMethodId: selectedPrintMethod?.id });

			const response = await printMethodsService.getPrintMethodAttributes(request);

			if (!response.isSuccess || !response.data) throw '';

			const options = response.data.map((_attribute) => ({ name: _attribute.name, value: _attribute.id, hexCode: _attribute.value, icon: <span className={styles.imageColor} style={{ '--bg-color': _attribute.value } as React.CSSProperties} /> }));

			if (!options) throw '';

			setMethodAttributes(options);
		} catch (error) {
		} finally {
		}
	};

	const getEmbroideryImage = async (designFile: File, oldColor?: string, newColor?: string) => {
		try {
			setLoading(true);

			const design = designFile;

			const request = new GetEmbroideryImageRequest({ designFile: design, oldColor: oldColor, newColor: newColor });

			const response = await printMethodsService.getEmbroideryImage(request);

			if (!response.isSuccess || !response.data) throw '';

			const embroideryImageFile = await fileHelper.urlToFile(response.data?.url || '');

			const methodAttributes: PrintMethodAttributeDto[] = response.data.colors.map((_color) => ({ attributeValue: _color.image_color, printMethodAttributeId: _color.id }));

			setSides(sides.map((_side) => (_side.id !== selectedSideId ? _side : { ...structuredClone(_side), designFile: embroideryImageFile, oldDesignFile: designFile, printMethodAttributes: methodAttributes, isNewUploadFile: true })));

			await getPrintMethodAttributes();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const buildProduct = async (pProduct: CoreProductSynchronizeDto) => {
		try {
			setLoading(true);
			setLoadingText('The product and side adjustments are being made.');

			const productSides: SideDesignDialog[] = [];

			for (const side of pProduct.sides) {
				if (productSides.findIndex((_productSide) => _productSide.id === side.id) > -1) continue;

				productSides.push(side);
			}

			const size = props.size || variantHelper.getSizes(pProduct.variants)[0];
			const color = props.color || variantHelper.getColors(pProduct.variants, { sort: 'DESC' })[0];

			const medias: ProductPrintSideMedia[] = [];

			for (const side of productSides) {
				if (!side.printInfo) side.printInfo = getProductPrintInfo(size, side);

				if (!side.sideImageBase64) medias.push(side.medias[0]);
			}

			const imageUrls: { sideId: string; url: string }[] = [];

			for (const media of medias) {
				imageUrls.push({
					url: amazonS3Helper.showFile(media.media, {
						resize: `${media.mediaWidth.toFixed()}x${media.mediaHeight.toFixed()}`
					}),
					sideId: productSides.find((_side) => _side.medias.find((_media) => _media.id === media.id))?.id || ''
				});
			}

			const responses: Base64Response[] = (await getImageUrl(imageUrls.map((_imageUrl) => _imageUrl.url))) || [];

			for (const response of responses) {
				const findedIndex = imageUrls.findIndex((_url) => _url.url === response.url);

				const sideIndex = productSides.findIndex((_side) => _side.id === imageUrls[findedIndex].sideId);

				productSides[sideIndex].sideImageBase64 = response.base64Image;
			}

			setAllSides(productSides);
			//setSides(productSides);
			setSelectedSideId(productSides[0].id);
			setDefaultColor(color);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	// const buildEditProduct = async (editProduct: ConfirmSideWithDesign) => {
	// 	try {
	// 		setLoading(true);
	// 		setLoadingText('The product and side adjustments are being made.');

	// 		const productSides: SideDesignDialog[] = [];

	// 		for (const side of editProduct.product.sides) {
	// 			if (productSides.findIndex((_productSide) => _productSide.id === side.id) > -1) continue;

	// 			const editSide = editProduct.sides.find((_editSide) => _editSide.id === side.id);

	// 			if (!!editSide) productSides.push(editSide);
	// 			else productSides.push(side);
	// 		}

	// 		const size = editProduct.size;

	// 		const medias: ProductPrintSideMedia[] = [];

	// 		for (const side of productSides) {
	// 			if (!side.printInfo) side.printInfo = getProductPrintInfo(size, side);
	// 			if (!!side.fabricJson) side.isEditSide = true;
	// 			if (!side.sideImageBase64) medias.push(side.medias[0]);
	// 		}

	// 		const imageUrls: { sideId: string; url: string }[] = [];

	// 		for (const media of medias) {
	// 			imageUrls.push({
	// 				url: amazonS3Helper.showFile(media.media, {
	// 					resize: `${media.mediaWidth.toFixed()}x${media.mediaHeight.toFixed()}`
	// 				}),
	// 				sideId: productSides.find((_side) => _side.medias.find((_media) => _media.id === media.id))?.id || ''
	// 			});
	// 		}

	// 		const responses: Base64Response[] = (await getImageUrl(imageUrls.map((_imageUrl) => _imageUrl.url))) || [];

	// 		for (const response of responses) {
	// 			const findedIndex = imageUrls.findIndex((_url) => _url.url === response.url);

	// 			const sideIndex = productSides.findIndex((_side) => _side.id === imageUrls[findedIndex].sideId);

	// 			productSides[sideIndex].sideImageBase64 = response.base64Image;
	// 		}

	// 		setSides(productSides);
	// 		setSelectedSideId(productSides.find((_side) => !!_side.designFile)?.id || productSides[0].id);
	// 		setDefaultColor(editProduct.color);
	// 		setSelectedSize(size);
	// 		setSelectedColor(editProduct.color);
	// 	} catch (error) {
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	const getPrintramDefaultSizes = () => {
		if (!selectedSize || !selectedSideId) return;

		const selectedSide = sides.find((_side) => _side.id === selectedSideId);
		if (!selectedSide) return;

		const dimension = selectedSide.printDimensions.find((_dimension) => _dimension.size.id === selectedSize.id);

		if (!dimension) return '';

		let stringData = `Printram Default Print Size ${dimension.defaultPrintSizeWidth}″x${dimension.defaultPrintSizeHeight}″\n`;
		stringData += `Printram Minimum Print Size ${dimension.minPrintSizeWidth}″x${dimension.minPrintSizeHeight}″\n`;
		stringData += `Printram Maximum Print Size ${dimension.maxPrintSizeWidth}″x${dimension.maxPrintSizeHeight}″`;

		return stringData;
	};

	const getSelectedSide = (): SideDesignDialog | undefined => sides.find((_side) => _side.id === selectedSideId);

	const handleOnConfirm = async () => {
		try {
			setLoadingText('The product and selections are being optimized.');
			setLoading(true);

			const variant = variantHelper.getVariantFromColorAndSize(product?.variants!, selectedColor!, selectedSize!);
			if (!variant) throw 'Variant not found';

			const responseSides = sides.filter((_side) => !!_side.mockupBase64 && !!_side.designFile && !!_side.fabricJson) || [];
			if (responseSides.length === 0) throw 'Side not found';

			const data: ConfirmSideWithDesign = {
				uniqueId: !!props.editProduct ? props.editProduct.uniqueId : UUID(),
				color: selectedColor!,
				sides: responseSides.map((_side) => ({ ..._side, productPrintSideMethodId: _side.printMethods.find((_method) => _method.printMethodId === selectedPrintMethod?.id)?.productPrintMethodId, productPrintSideMethodName: _side.printMethods.find((_method) => _method.printMethodId === selectedPrintMethod?.id)?.name })),
				size: selectedSize!,
				product: product!,
				variant
			};

			props.onConfirm?.(data);
			handleOnClose();
		} catch (error) {
			toastHelper.warning((error as string) || '');
		} finally {
			setLoading(false);
		}
	};

	const headerTemplate = () => (
		<div className="flex flex-row justify-content-between align-items-center">
			<span>{product?.productName}</span>
			<div className="text-base font-normal">
				{/* <StartTutorialTourButton onClickEvent={() => {
					Cookies.set('product-select-intro-1', 'false');
					Cookies.set('product-select-intro-2', 'false');
					setTutorialStepsEnabled(true)
				}} /> */}
			</div>
		</div>
	);

	const completeTutorialStep1 = () => {
		setTutorialStepsEnabled(false);
		if (selectedColor && selectedSize) setTutorialStepsEnabled2(true);
	};

	const onEmbroideryColorChange = (mainColor: string, selectedColor: PrColorPickerType) => {
		setSides(
			sides.map((_side) =>
				_side.id !== selectedSideId
					? _side
					: {
							...structuredClone(_side),
							printMethodAttributes: _side.printMethodAttributes?.map((_attribute) => (_attribute.attributeValue !== mainColor ? _attribute : { ..._attribute, printMethodAttributeId: selectedColor.value.toString() }))
					  }
			)
		);

		const oldDesignFile = sides.find((_side) => _side.id === selectedSideId)?.oldDesignFile;

		if (!oldDesignFile) return;

		getEmbroideryImage(oldDesignFile, mainColor, selectedColor.hexCode.toString());
	};

	const isTabVisible = (tabValue: number) => {
		if (tabValue === 2) {
			if (selectedPrintMethod?.type !== 3) return false;
			else {
				if (!sides.find((_side) => _side.id === selectedSideId)?.designFile) return false;
				else return true;
			}
		} else return true;
	};

	useEffect(() => {
		if (!!props.product) {
			clearStates();
			setProduct(props.product);
			buildProduct(props.product);
		}
		// else if (!props.product && !!props.editProduct) {
		// 	clearStates();
		// 	setProduct(props.editProduct.product);
		// 	buildEditProduct(props.editProduct);
		// }
	}, [props.product]);

	useEffect(() => {
		if (buildMockup && loadings.length === 0) {
			setBuildMockup(false);
			handleOnConfirm();
		}
	}, [loadings.length]);

	useEffect(() => {
		if (!!sidesContainerRef.current) {
			setShowLeftBtn(sidesContainerRef.current.scrollLeft > 1);
			setShowRightBtn(sidesContainerRef.current.scrollLeft + 45 * 16 < sidesContainerRef.current?.scrollWidth);
		}
	}, [sidesContainerRef.current]);

	useEffect(() => {
		if (!selectedColor || !selectedSize || Cookies.get('product-select-intro-2') === 'true') return;

		setTutorialStepsEnabled2(true);
	}, [selectedColor, selectedSize]);

	useEffect(() => {
		if (!allSides || allSides.length === 0) return;

		setSelectedPrintMethod(props.product?.availablePrintMethods?.find((_method) => _method.type === 1) || undefined);
	}, [allSides]);

	useEffect(() => {
		if (!selectedPrintMethod) return;

		const filteredSides = allSides.filter((_side) =>
			_side.printMethods.find((_method) => {
				if (_method.printMethodId === selectedPrintMethod.id) return _method;
				else return null;
			})
		);

		setSides(filteredSides);
		setSelectedSideId(filteredSides[0].id);
	}, [selectedPrintMethod]);

	useEffect(() => {
		if (Cookies.get('product-select-intro-1') === 'true') return;

		setTutorialStepsEnabled(true);
	}, []);

	return (
		<Dialog header={headerTemplate} draggable={false} visible={props.visible} onHide={() => handleOnClose()} className={styles.dialog}>
			{/* <Steps enabled={tutorialStepsEnabled} steps={steps1} initialStep={0} onExit={() => setTutorialStepsEnabled(false)} onComplete={() => completeTutorialStep1()} options={{ showBullets: false, dontShowAgainCookie: 'product-select-intro-1', disableInteraction: false }} />
			<Steps enabled={tutorialStepsEnabled2} steps={steps2} initialStep={0} onExit={() => setTutorialStepsEnabled2(false)} onComplete={() => setTutorialStepsEnabled2(false)} options={{ showBullets: false, dontShowAgainCookie: 'product-select-intro-2', disableInteraction: false }} /> */}

			<main
				className={styles.wrapper}
				onDragOver={(e) => e.preventDefault()}
				onDrop={(e) => {
					e.preventDefault();

					const file = e.dataTransfer.files[0];

					if (fileHelper.isBiggerThenMb(file)) {
						toastHelper.warning('The file size cannot exceed 10MB.');
						return;
					}

					if (acceptedFileExtensions.basics.findIndex((_extension) => file.name.endsWith(_extension)) === -1) {
						toastHelper.warning('File extension is not supported.');
						return;
					}

					setSides(
						sides.map((_side) =>
							_side.id !== selectedSideId
								? _side
								: {
										...structuredClone(_side),
										designFile: file,
										isNewUploadFile: true
								  }
						)
					);

					if (selectedPrintMethod?.type === 3) {
						setSelectedTab(2);
						getEmbroideryImage(file);
					}
				}}>
				<aside className={styles.tabs}>
					{tabs.map((_tab) => (
						<div key={_tab.value} onClick={() => setSelectedTab(_tab.value)} className={`${styles.card} ${_tab.value === selectedTab ? styles.isActive : ''} ${!isTabVisible(_tab.value) ? styles.hide : styles.visible}`}>
							{_tab.icon}
							<span>{_tab.label}</span>
						</div>
					))}
				</aside>

				{selectedTab === 1 ? (
					<aside className={styles.info}>
						<h5>
							<span>Choosed Product Details</span>
						</h5>

						<div className={styles.productInfo}>
							<p className="m-0">
								Name &#x2022; <span className="font-bold">{product?.productName}</span>
							</p>

							<p className="my-2">
								Model &#x2022;{' '}
								<span className="font-bold">
									{product?.model.brand.name} {product?.model.name}
								</span>
							</p>

							{!props.editProduct ? <PrButton btnType="button" text="Change Product" type="secondary" onClick={() => props.onChangeProduct?.()} className={styles.changeProductBtn} /> : null}

							{!!props.syncProduct ? (
								<React.Fragment>
									<div className={styles.title}>
										<span>Etsy Order Details</span>
									</div>

									<div className={styles.productInfo}>
										<div className="mt-4 mb-6">
											<p className="m-0">
												Name &#x2022; <span className="font-bold" dangerouslySetInnerHTML={{ __html: props.syncProduct.title }} />
											</p>

											{props.syncProduct.variations.map((_vari, index) => (
												<div key={index}>
													<p className="m-0">
														<span dangerouslySetInnerHTML={{ __html: _vari.key }} /> &#x2022; <span className="font-bold" dangerouslySetInnerHTML={{ __html: _vari.value }} />
													</p>
												</div>
											))}

											<p className="m-0">
												SKU &#x2022; <span className="font-bold" dangerouslySetInnerHTML={{ __html: props.syncProduct.sku }} />
											</p>
										</div>
									</div>
								</React.Fragment>
							) : null}

							{props.product?.availablePrintMethods ? (
								<React.Fragment>
									<div className={styles.title}>
										<span>Print Technique</span>
									</div>

									<div className={styles.printMethods}>
										{props.product?.availablePrintMethods.map((_method) => (
											<div key={_method.id} className={`${styles.card} ${_method.id === selectedPrintMethod?.id ? styles.isSelected : ''}`} onClick={() => setSelectedPrintMethod(_method)}>
												<span>{_method.name}</span>
											</div>
										))}
									</div>
								</React.Fragment>
							) : null}

							{!!product ? (
								<React.Fragment>
									<div className="my-4" style={{ background: 'var(--surface-400)', height: '2px' }} />

									<p className="m-0 font-bold">Choose Color</p>

									<div className={`${styles.colorWrapper} tutorial-selector1`}>
										{variantHelper.getColors(product.variants, { sort: 'DESC' }).map((_color, index) => (
											<div
												key={index}
												id={`color${_color.id}`}
												role="button"
												tabIndex={0}
												data-pr-tooltip={`${_color.name} ${_color.hexCode}`}
												data-pr-position="top"
												className={`${styles.colorItem}${selectedColor?.id === _color.id ? ` ${styles.selected}` : ''}`}
												style={
													{
														'--color-item-bg-color': _color.hexCode,
														backgroundImage: `url('${_color.imageUrl}')`,
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
														backgroundSize: 'cover'
													} as React.CSSProperties
												}
												onClick={() => {
													setSelectedColor(_color);
												}}>
												<Tooltip target={`#color${_color.id}`} />
											</div>
										))}
									</div>

									<div style={{ background: 'var(--surface-400)', height: '2px' }} />

									<p className="m-0 mt-4 font-bold">Choose Size</p>

									<div className={`${styles.sizeWrapper} tutorial-selector2`}>
										{variantHelper.getSizes(product.variants).map((_size, index) => (
											<div
												key={index}
												id={`size${_size.id}`}
												role="button"
												tabIndex={0}
												data-pr-tooltip={`${_size.name}`}
												data-pr-position="top"
												className={`${styles.sizeItem}${selectedSize?.id === _size.id ? ` ${styles.selected}` : ''}`}
												onClick={() => {
													setSelectedSize(_size);

													const clonedSides: SideDesignDialog[] = structuredClone(sides);

													for (const side of clonedSides) {
														if (side.id === selectedSideId && !!side.designFile) {
															const copiedSidePrintInfo = JSON.parse(JSON.stringify(side.printInfo));

															side.printInfo = {
																...getProductPrintInfo(_size, side)!,
																printWidth: copiedSidePrintInfo.printWidth!
															};
														} else side.printInfo = getProductPrintInfo(_size, side);

														side.isChangeSize = true;
													}

													setSides(clonedSides);
												}}>
												<Tooltip target={`#size${_size.id}`} />
												{_size.shortName}
											</div>
										))}
									</div>
								</React.Fragment>
							) : null}

							{/* <div style={{ background: 'var(--surface-400)', height: '2px' }} />

						<p className="m-0 mt-4 font-bold">Print Sides</p>
						<small className="mb-2 block text-color-secondary">
							The first surface you want (front, back, front, etc.) is <b>always free</b>. However, if you want to print on more than one area, our printing prices are listed below
						</small>

						{sides.length > 0 ? (
							<div className={styles.sideWrapper}>
								{sides.map((_side, index) => (
									<div key={index} className={styles.sideItem}>
										<span>{_side.name}</span>
										<span>{currencyHelper.formatPrice(_side.printPrice.formattedPricePerUnit)}</span>
									</div>
								))}
							</div>
						) : (
							<h5 className="m-0 py-4 px-2 flex align-items-center font-semibold text-color-secondary justify-content-center surface-ground pointer-events-none">No print sides found</h5>
						)} */}
						</div>
					</aside>
				) : null}

				{selectedTab === 2 ? (
					<aside className={styles.info}>
						<h5>
							<span>Thread Colors</span>
						</h5>

						<h6 className="mb-1 mt-3">Embroidery Type</h6>
						<span>Flat Embroidery</span>

						<h6 className="mb-1 mt-3">Choose thread colors (max 6)</h6>

						<div className={styles.embroideryColors}>
							{sides.find((_side) => _side.id === selectedSideId) &&
								sides
									.find((_side) => _side.id === selectedSideId)
									?.printMethodAttributes?.map((_attribute, index) => (
										<div key={index} className={styles.embroideryColorsCard}>
											<div className={styles.imageColor} style={{ '--bg-color': _attribute.attributeValue } as React.CSSProperties} />
											<FaArrowRight />
											<PrColorPicker options={methodAttributes} value={{ name: methodAttributes.find((_method) => _method.value === _attribute.printMethodAttributeId)?.name || '', value: _attribute.printMethodAttributeId, hexCode: _attribute.attributeValue, icon: methodAttributes.find((_method) => _method.value === _attribute.printMethodAttributeId)?.icon }} onChange={(e) => onEmbroideryColorChange(_attribute.attributeValue, e)} />
										</div>
									))}
						</div>
					</aside>
				) : null}

				<section className={styles.container}>
					{!!selectedColor && !!selectedSize && !!selectedPrintMethod ? (
						<div
							style={{
								position: 'relative',
								textAlign: 'center',
								maxWidth: '51rem',
								marginInline: 'auto',
								zIndex: 0
							}}>
							{
								<div ref={sidesContainerRef} className={`${styles.sideTabWrapper} tutorial-selector3`}>
									{sides.map((_side, index) => (
										<div
											key={index}
											role="button"
											tabIndex={0}
											className={`${styles.sideTabItem}${!selectedSideId ? ` ${styles.disabled}` : ''}${selectedSideId === _side.id ? ` ${styles.selected}` : ''}`}
											onClick={() => {
												setSelectedSideId(_side.id);
												if (!_side.designFile) setSelectedTab(1);
											}}>
											{_side.name}
										</div>
									))}
								</div>
							}

							<div className={styles.sideTabButtonContainer}>
								<span
									role="button"
									tabIndex={0}
									className={styles.sideTabButtonContainerPrev}
									style={{ display: showLeftBtn ? 'inline-flex' : 'none' }}
									onClick={() => {
										if (!sidesContainerRef.current) return;

										const scrollLeft = sidesContainerRef.current.scrollLeft - 300;

										sidesContainerRef.current.scrollLeft = scrollLeft;

										setShowLeftBtn(scrollLeft > 1);
										setShowRightBtn(scrollLeft + 45 * 16 < sidesContainerRef.current?.scrollWidth);
									}}>
									<FaChevronLeft />
								</span>

								<span
									role="button"
									tabIndex={0}
									className={styles.sideTabButtonContainerNext}
									onClick={() => {
										if (!sidesContainerRef.current) return;

										const scrollLeft = sidesContainerRef.current.scrollLeft + 300;

										sidesContainerRef.current.scrollLeft = scrollLeft;

										setShowLeftBtn(scrollLeft > 1);
										setShowRightBtn(scrollLeft + 45 * 16 < sidesContainerRef.current?.scrollWidth);
									}}
									style={{ display: showRightBtn ? 'inline-flex' : 'none' }}>
									<FaChevronRight />
								</span>
							</div>
						</div>
					) : (
						<div className={styles.noSelect}>
							<div className={styles.image} />
							<span>Please select color and size information from the menu on the left to view the preview.</span>
						</div>
					)}

					{!!selectedSideId && !!defaultColor ? (
						<div className={`${styles.designWrapper} ${!!selectedSize && !!selectedColor && !!selectedPrintMethod ? 'flex' : 'hidden'}`}>
							<div className={`${styles.mockupSide}`}>
								{sides.map((_side, index) => (
									<Preview key={_side.id} side={_side} setSides={setSides} setLoadings={setLoadings} isActive={_side.id === selectedSideId} color={selectedColor || defaultColor} buildMockup={buildMockup} setSelectedTab={setSelectedTab} />
								))}
							</div>

							<div className={styles.optionsSide}>
								<div className={styles.fileArea}>
									<label htmlFor="printFile" className={`${styles.printFileWrapper} tutorial-selector4`}>
										<input
											type="file"
											value={''}
											onChange={(e) => {
												if (!e.target.files) return;

												const file = e.target.files[0];

												if (fileHelper.isBiggerThenMb(file)) {
													toastHelper.warning('The file size cannot exceed 10MB.');
													return;
												}

												if (acceptedFileExtensions.basics.findIndex((_extension) => file.name.endsWith(_extension)) === -1) {
													toastHelper.warning('File extension is not supported.');
													return;
												}

												setSides(
													sides.map((_side) =>
														_side.id !== selectedSideId
															? _side
															: {
																	...structuredClone(_side),
																	designFile: file,
																	isNewUploadFile: true
															  }
													)
												);

												if (selectedPrintMethod?.type === 3) {
													setSelectedTab(2);
													getEmbroideryImage(file);
												}
											}}
											accept={acceptedFileExtensions.basics.join(', ')}
											name="printFile"
											id="printFile"
											className="hidden"
										/>

										<div role="button" tabIndex={0} className={styles.printFileBtn}>
											<FiPlus size="1.808rem" />
											<span>Print File</span>
										</div>

										<div className={styles.printFileDivider} />

										<div className={styles.printFileInfo}>
											<span className={styles.printTitle}>Drag and Drop "Print Files" Here</span>
											<span className={styles.printAccepts}>You can upload: {acceptedFileExtensions.basics.join(', ')}, Files</span>
										</div>
									</label>

									{sides.findIndex((_side) => _side.id === selectedSideId && !!_side.designFile) > -1 ? (
										<PrButton
											id="deleteFileBtn"
											tooltip="Clear selected design"
											onClick={() => {
												setSides(
													sides.map((_side) =>
														_side.id !== selectedSideId
															? _side
															: {
																	...structuredClone(_side),
																	designFile: undefined,
																	fabricJson: undefined,
																	mockupBase64: undefined,
																	isNewUploadFile: undefined
															  }
													)
												);
												setSelectedTab(1);
											}}
											icon={<span className="pi pi-fw pi-trash" />}
											type="icon"
											className={styles.deleteDesignBtn}
										/>
									) : null}
								</div>

								<div className={styles.sizeInputContainer}>
									<div>
										{getSelectedSide()?.name} Size (Recommended){' '}
										<span id="printCurrentSizes" data-pr-tooltip={getPrintramDefaultSizes()} data-pr-position="top" className="pi pi-fw pi-info-circle">
											<Tooltip target="#printCurrentSizes" />
										</span>
									</div>

									<div className={styles.inputWrapper}>
										<div className={styles.inputWrapperBody}>
											<div className={styles.inputGroup}>
												<PrTextInput
													type="number"
													disabled={!getSelectedSide()?.designFile}
													value={Math.round((getSelectedSide()?.printInfo?.printWidth || 0) * 10) / 10}
													onChange={(e) => {
														if (!selectedSize || !getSelectedSide()?.printInfo) return;

														let width = !!e.target.value ? (Number(e.target.value) < getSelectedSide()!.printInfo!.minPrintWidth ? getSelectedSide()!.printInfo!.minPrintWidth : Number(e.target.value)) : getSelectedSide()!.printInfo!.minPrintWidth;

														if (width > getSelectedSide()!.printInfo!.maxPrintWidth) width = getSelectedSide()!.printInfo!.maxPrintWidth;

														if (getSelectedSide()!.printInfo!.printHeight >= getSelectedSide()!.printInfo!.maxPrintHeight && getSelectedSide()!.printInfo!.printWidth < width) return;

														setSides(
															sides.map((_side) => {
																if (_side.id !== selectedSideId || !_side.printInfo) return _side;

																_side.printInfo.printWidth = width;

																return _side;
															})
														);
													}}
												/>
												<span>in</span>
											</div>

											<span>X</span>

											<div className={styles.inputGroup}>
												<PrTextInput
													type="number"
													value={Math.round((getSelectedSide()?.printInfo?.printHeight || 0) * 10) / 10}
													disabled={!getSelectedSide()?.designFile}
													onChange={(e) => {
														if (!selectedSize || !getSelectedSide()?.printInfo) return;

														let height = !!e.target.value ? (Number(e.target.value) < getSelectedSide()!.printInfo!.minPrintHeight ? getSelectedSide()!.printInfo!.minPrintHeight : Number(e.target.value)) : getSelectedSide()!.printInfo!.minPrintHeight;

														if (height > getSelectedSide()!.printInfo!?.maxPrintHeight) height = getSelectedSide()!.printInfo!?.maxPrintHeight;

														if (getSelectedSide()!.printInfo!.printWidth >= getSelectedSide()!.printInfo!.maxPrintWidth && getSelectedSide()!.printInfo!.printHeight < height) return;

														setSides(
															sides.map((_side) => {
																if (_side.id !== selectedSideId || !_side.printInfo) return _side;

																_side.printInfo.printHeight = height;

																return _side;
															})
														);
													}}
												/>
												<span>in</span>
											</div>

											<PrButton
												text="Set Default"
												disabled={!getSelectedSide()?.designFile}
												icon={<FaCog />}
												onClick={async () => {
													try {
														setLoading(true);

														const response = await userPrintSideDimensionService.createOrUpadte(
															new CreateOrUpdateUserPrintSideDimensionRequest({
																userId: user?.userId,
																sizeId: selectedSize?.id,
																productPrintSideId: getSelectedSide()?.id,
																defaultPrintSizeWidth: getSelectedSide()?.printInfo?.printWidth,
																defaultPrintSizeHeight: getSelectedSide()?.printInfo?.printHeight
															})
														);
														if (!response.isSuccess) throw '';
													} catch (error) {
													} finally {
														setLoading(false);
													}
												}}
												style={{ height: '2rem', marginLeft: '.5rem' }}
											/>
										</div>

										<div className={styles.inputWrapperFooter}>
											<small
												role="button"
												tabIndex={0}
												onClick={() => setPrintSettingsVisible(true)}
												style={{
													textDecoration: 'underline',
													color: '#A9A9A9',
													cursor: 'pointer'
												}}>
												Check your default settings
											</small>
										</div>
									</div>

									<div className={styles.staticWrapper}>
										<span className={styles.icon}>
											<CiCircleInfo size="2.75rem" />
										</span>

										<span className={styles.text}>Most of our customers (95%) use these recommended sizes. They are standard for prints. We suggest keeping them as is.</span>
									</div>
								</div>
							</div>
						</div>
					) : null}
				</section>
			</main>

			<footer className={styles.footer}>
				{!!product?.variants && selectedColor && selectedSize && !!variantHelper.getVariantFromColorAndSize(product?.variants, selectedColor, selectedSize) ? (
					<p className="m-0 mr-4 font-bold" style={{ fontSize: '1.125rem' }}>
						{currencyHelper.formatPrice(variantHelper.getVariantFromColorAndSize(product?.variants, selectedColor, selectedSize)?.salePrice.formattedPricePerUnit || 0)}
					</p>
				) : null}

				<PrButton
					btnType="button"
					text="Confirm"
					disabled={sides.findIndex((_side) => !!_side.designFile && !!_side.fabricJson) === -1}
					icon={<FaChevronRight />}
					onClick={() => {
						setLoadingText('Mockups creating');
						setBuildMockup(true);
					}}
				/>
			</footer>

			<DefaultPrintOptionsDialog coreProductId={product?.id} visible={printSettingsVisible} setVisible={setPrintSettingsVisible} />
			<PrLoading loading={loading || loadings.length > 0} text={loadingText} />
		</Dialog>
	);
};
