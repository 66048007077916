import { UpsertPrintMethodAttributeRequest } from 'features/print-methods/_common/upsert-print-method-attribute/upsert-print-method-attribute.request';
import { PrintMethodAttributeDto } from 'features/print-methods/dtos/print-method-attribute.dto';
import { Dialog } from 'primereact/dialog';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import printMethodsService from 'features/print-methods/_common/print-methods.service';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { ColorPicker } from 'primereact/colorpicker';

type Props = {
	isVisible: boolean;
	setVisible: Function;
	getAttributes: Function;
	editedAttribute?: PrintMethodAttributeDto;
	setEditedAttribute?: Function;
	printMethodId: string;
};

const UpsertPrintMethodAttribute = ({ isVisible, setVisible, getAttributes, editedAttribute, setEditedAttribute, printMethodId }: Props) => {
	const [selectedColor, setSelectedColor] = useState(editedAttribute?.value || '');
	const [loading, setLoading] = useState<boolean>(false);

	const initialValues: UpsertPrintMethodAttributeRequest = new UpsertPrintMethodAttributeRequest({ id: editedAttribute?.id || '', printMethodId: editedAttribute?.printMethodId || printMethodId, type: editedAttribute?.type || 1, name: editedAttribute?.name || '', value: editedAttribute?.value || '' });

	const validationScheme = Yup.object().shape({ name: Yup.string().required('Name is required').min(1, 'Name must be at least 1 characters.').max(200, 'Name must be a maximum of 200 characters.') });

	const submitForm = async (values: UpsertPrintMethodAttributeRequest) => {
		try {
			setLoading(true);

			const request = new UpsertPrintMethodAttributeRequest(values);

			request.value = selectedColor;

			const response = await printMethodsService.upsertPrintMethodAttribute(request);

			if (!response.isSuccess) throw '';

			setVisible(false);
			setEditedAttribute && setEditedAttribute(undefined);
			getAttributes();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const setColor = (hexCode: string) => {
		hexCode.charAt(0) !== '#' ? setSelectedColor('#' + hexCode) : setSelectedColor(hexCode);
	};

	useEffect(() => {
		if (!editedAttribute || !editedAttribute.value) return;

		setSelectedColor(editedAttribute.value);
	}, [editedAttribute]);

	return (
		<Dialog
			visible={isVisible}
			header={editedAttribute ? 'Update Attribute' : 'Create Attribute'}
			modal
			onHide={() => {
				setVisible(false);
				setEditedAttribute && setEditedAttribute(undefined);
			}}
			className="max-w-30rem">
			<Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
				{({ values, handleChange }) => (
					<Form>
						<div className="flex flex-column gap-2">
							<PrTextInput name="name" value={values.name} onChange={handleChange} placeholder="Enter attribute name" label={''} />

							<ColorPicker value={selectedColor} onChange={(e: any) => setColor(e.value.toString())} inline className="flex align-items-center justify-content-center" />
							<PrTextInput value={selectedColor} onChange={(e: any) => setColor(e.target.value.toString())} placeholder="Enter attribute value" label={''} />

							<div className="flex justify-content-end">
								<PrButton btnType="submit" text={editedAttribute ? 'Update Attribute' : 'Create Attribute'} loading={loading} className="px-4" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default UpsertPrintMethodAttribute;
