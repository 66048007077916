import { CreateCustomDtfContext, CustomDtfContextProvider, CustomDtfContextType } from '../../context/CustomDtfContextProvider';
import React, { useEffect, useState } from 'react';
import styles from '../../CustomDtf.module.scss';
import { Accordion, AccordionTab } from 'primereact/accordion';
import CustomDtfOrder from '../Order/CustomDtfOrder';
import CustomDtfShipment from '../Shipment/CustomDtfShipment';
import { CustomDtfShipSummary } from '../ShipSummary/CustomDtfShipSummary';
import CustomDtfCheckout from '../Checkout/CustomDtfCheckout';
import Spinner from '../../Helper/Spinner';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const CreateCustomDtfOrder = () => {
	const context: CustomDtfContextType = React.useContext(CreateCustomDtfContext) as CustomDtfContextType;
	const navigate = useNavigate();

	const [isNavigating, setIsNavigating] = useState(false); // State to track navigation

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (!isNavigating) {
				const confirmationMessage = 'Are you sure you want to leave? Files you uploaded will be removed.';
				event.preventDefault();
				event.returnValue = confirmationMessage; // Standard for most browsers
				return confirmationMessage;
			}
		};

		// Add event listener for beforeunload
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload); // Cleanup the listener
		};
	}, [isNavigating]);

	useEffect(() => {
		// Handle back/forward navigation
		const handlePopState = (event: any) => {
			event.preventDefault(); // Prevent the default back navigation

			confirmDialog({
				header: 'Are you sure?',
				message: 'Files you uploaded will be removed.',
				accept: () => navigate(-1), // Go back using React Router
				reject: () => window.history.pushState(null, '', window.location.href) // Stay on the current page
			});
		};

		// Handle tab close or page refresh
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			const confirmationMessage = 'Are you sure you want to leave? Files you uploaded will be removed.';

			event.preventDefault();
			event.returnValue = confirmationMessage; // Standard for most browsers
			return confirmationMessage; // Some browsers may require this return for showing the dialog
		};

		// Add event listeners
		window.history.pushState(null, '', window.location.href); // Prevent initial back navigation
		window.addEventListener('popstate', handlePopState);
		window.addEventListener('beforeunload', handleBeforeUnload);

		// Cleanup event listeners when the component is unmounted
		return () => {
			window.removeEventListener('popstate', handlePopState);
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [navigate]);

	const headerTemplate = (text: string, completed: boolean) => {
		return (
			<div className={'flex flex-row justify-content-start align-items-center w-full'}>
				<h6 className={`${styles.AccHeader} m-0`}>{text}</h6>
				{completed && <div className={'pi pi-check-circle'} />}
			</div>
		);
	};

	return (
		<div className="relative">
			<h4 className={'m-0'}>New Order</h4>
			<Accordion className={styles.CustomAccordion} collapseIcon={null} expandIcon={null} activeIndex={context.activeIndex} onTabChange={(e) => context.setActiveIndex(e.index)}>
				<AccordionTab disabled header={'Order'} children={<CustomDtfOrder />} />
				<AccordionTab disabled header={'Shipping Details'} children={<CustomDtfShipment />} />
				<AccordionTab disabled header={'Summary'} children={<CustomDtfShipSummary />} />
				<AccordionTab disabled header={'Checkout'} children={<CustomDtfCheckout />} />
			</Accordion>

			{context.loading && <Spinner />}
			<ConfirmDialog />
		</div>
	);
};

const CustomDtfWrapper = () => {
	return (
		<CustomDtfContextProvider>
			<CreateCustomDtfOrder />
		</CustomDtfContextProvider>
	);
};

export default CustomDtfWrapper;
