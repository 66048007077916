import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import React, { useEffect , useContext , useRef , useState } from 'react';
import { RTLContext } from '../App';
import { Tooltip } from 'primereact/tooltip';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logout } from '../redux/features/auth/authSlice';
import { Auth } from '../models/dtos/auth/auth';
import jwtParserHelper from '../helpers/jwt-parser.helper';
import AvatarImage from '../helpers/widgets/AvatarImage';
import { useNavigate } from 'react-router-dom';
import colorHelper from '../helpers/color.helper';
import person from '../assets/icons/user.svg';
import security from '../assets/icons/shield-tick.svg';
import setting from '../assets/icons/setting.svg';
import login from '../assets/icons/login.svg';
import arrow from '../assets/icons/arrow-down.svg'

const AppInlineMenu = (props: any) => {
	const { user, accessToken } = useAppSelector((state) => state.auth.data || ({} as Auth));
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const isRTL = useContext(RTLContext);
	const menuKey = props.menuKey || 'inline-menu';

	const bgColor = user.avatarBackgroundColor || '#D2001A';
	const textColor = colorHelper.getContrast(bgColor);
	const dangerColor = {color: "#EF4444"};

	const menuRef = useRef<HTMLUListElement>(null);
	const [menuStyle, setMenuStyle] = useState<React.CSSProperties>({});
	const [menuPositionChecked, setMenuPositionChecked] = useState(false);

	const inlineMenuClassName = classNames(
		'layout-inline-menu',
		{
			'layout-inline-menu-active': props.inlineMenuActive[props.menuKey]
		},
		props.className
	);

	const isSlim = () => {
		return props.menuMode === 'slim';
	};

	const currentRoles = (): string => {
		const role = jwtParserHelper.getReadableRoles(accessToken.token);
		if (typeof role === 'string') return role;

		return role.join(' ');
	};

	const adjustMenuPosition = () => {
		const menuElement = menuRef.current;
		if (menuElement) {
			const rect = menuElement.getBoundingClientRect();
			const windowHeight = window.innerHeight;

			if (rect.bottom > windowHeight) {
				const offset = rect.bottom - windowHeight;
				setMenuStyle({ bottom: `${offset / 2}px` });
			} else {
				setMenuStyle({ top: 'auto' });
			}
		}
	};

	useEffect(() => {
		if (props.inlineMenuActive[menuKey] && !menuPositionChecked) {
			adjustMenuPosition();
			setMenuPositionChecked(true);
		}
	}, [props.inlineMenuActive[menuKey], menuPositionChecked]);

	useEffect(() => {
		window.addEventListener('resize', adjustMenuPosition);

		return () => {
			window.removeEventListener('resize', adjustMenuPosition);
		};
	}, []);

	return (
		<div className={inlineMenuClassName} style={props.style}>
			{isSlim() && <Tooltip target=".avatarTooltip" />}

			{!!user && (
				<button data-pr-tooltip="Amy Elsner" className={classNames('avatarTooltip layout-inline-menu-action p-link flex flex-row align-items-center', {
					'p-3 lg:p-1 py-3': props.horizontal,
					'p-3': !props.horizontal
				})} onClick={(e) => props.onInlineMenuClick(e, menuKey)}>
					<AvatarImage/>

					<span className={classNames('flex flex-column', {'ml-2': !isRTL, 'mr-2': isRTL} , "")}>
						<span
							className="font-bold">{user.corporateInfo?.businessName || user.personInfo?.fullName}</span>

						<small>
							<span>{currentRoles()}</span>
							{user.roles.isSeller && user.subscription ? <span> | {user.subscription.name}</span> : null}
						</small>
					</span>
					<img src={arrow} alt="" className={classNames('layout-inline-menu-icon pi pi-angle-down', { 'ml-auto': !isRTL, 'mr-auto': isRTL })}/>
				</button>
			)}

			<CSSTransition classNames="slide-horizontal" timeout={{enter: 300, exit: 200}} in={props.inlineMenuActive[menuKey]} unmountOnExit>
				<React.Fragment>
					<ul ref={menuRef} className="layout-inline-menu-action-panel" style={menuStyle}>
						{!user.roles.isPackager ? (
							<React.Fragment>
								<li onClick={() => navigate('/account/details')} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="MyAccount">
									<button className="flex flex-row align-items-center p-link">
										<img src={person} alt=""/>
										<span className="">My Account</span>
									</button>
								</li>

								{/*{user.roles.isSeller ? (
									<React.Fragment>
										{/* <li onClick={() => navigate('/account/connections')} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Connections">
									<button className="flex flex-row align-items-center p-link">
										<i className="pi pi-arrow-right-arrow-left pi-fw"></i>
										<span>Connections</span>
									</button>
								</li>
								<li onClick={() => navigate('/account/my-stores')} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="MyStores">
											<button className="flex flex-row align-items-center p-link">
												<i className="pi pi-building pi-fw"></i>
												<span>My Stores</span>
											</button>
										</li>
										 <li onClick={() => navigate('/account/credit-cards')} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Payment Methods">
									<button className="flex flex-row align-items-center p-link">
										<i className="pi pi-credit-card pi-fw"></i>
										<span>Payment Methods</span>
									</button>
								</li>
									<li onClick={() => navigate('/account/plans')} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Plans">
											<button className="flex flex-row align-items-center p-link">
												<i className="pi pi-id-card pi-fw"></i>
												<span>Plans</span>
											</button>
										</li>
										<li onClick={() => navigate('/account/payments')} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Payments">
											<button className="flex flex-row align-items-center p-link">
												<i className="pi pi-wallet pi-fw"></i>
												<span>Payments</span>
											</button>
										</li>
										<li onClick={() => navigate('/account/print-options')} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Print Options">
											<button className="flex flex-row align-items-center p-link">
												<i className="pi pi-file-export pi-fw"></i>
												<span>Print Options</span>
											</button>
										</li>
									</React.Fragment>
								) : null}*/}

								<li onClick={() => navigate('/account/security')} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Security">
									<button className="flex flex-row align-items-center p-link">
										<img src={security} alt=""/>
										<span className="">Security</span>
									</button>
								</li>

								<li onClick={() => navigate('/account/settings')} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Settings">
									<button className="flex flex-row align-items-center p-link">
										<img src={setting} alt=""/>
										<span className="">Settings</span>
									</button>
								</li>
							</React.Fragment>
						) : null}

						<li onClick={() => dispatch(logout())} className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Logout">
							<button className="flex flex-row align-items-center p-link" style={dangerColor}>
								<img src={login} alt=""/>
								<span className="">Logout</span>
							</button>
						</li>
					</ul>

					{isSlim() && <Tooltip target=".tooltip" />}
				</React.Fragment>
			</CSSTransition>
		</div>
	);
};

export default AppInlineMenu;
