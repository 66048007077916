import { Menu } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import styles from './PrColorPicker.module.scss';

export interface PrColorPickerType {
	name: string | number;
	value: string | number;
	hexCode: string | number;
	icon?: JSX.Element;
}

type Props = {
	options: PrColorPickerType[];
	value: PrColorPickerType;
	placeholder: string;
	disabled: boolean;
	className: string;
	onChange: (event: PrColorPickerType) => void;
};

const PrColorPicker = (props: Partial<Props>) => {
	const [options, setOptions] = useState<PrColorPickerType[]>([]);
	const [itemonHover, setItemonHover] = useState<PrColorPickerType>();

	useEffect(() => {
		setOptions(props.options || []);
	}, [props.options]);

	return (
		<Menu as="div" className={`pr-dropdown${props.className ? ` ${props.className}` : ` ${styles.main}`}`}>
			<Menu.Button className={`pr-btn pr-btn-secondary ${styles.button}`} disabled={props.disabled}>
				<span className={`${styles.labelWrapper}`}>
					{!!props.value && props.value.icon ? <span className="inline-flex mr-1">{props.value.icon}</span> : null}
					<div className={styles.label}>{!!props.value ? props.value.name : props.placeholder || 'Select'}</div>
				</span>

				<IoIosArrowDown />
			</Menu.Button>

			<Menu.Items className="pr-dropdown-menu right">
				<span className={styles.colorInfos}>{`${itemonHover ? `${itemonHover?.name} (${itemonHover?.hexCode})` : 'Select a color'}`}</span>

				<div className={styles.items}>
					{options.length > 0 ? (
						options.map((_option, index) => (
							<Menu.Item key={index} onMouseOver={() => setItemonHover(_option)} onClick={() => props.onChange?.(_option)} as="button" className={`pr-btn ${props.value?.value === _option.value ? ' active-item' : ''} ${styles.item}`}>
								{_option?.icon && <span className="inline-flex">{_option.icon}</span>}
							</Menu.Item>
						))
					) : (
						<Menu.Item as="div" className={`h-3rem flex align-items-center justify-content-center px-2`} style={{ backgroundColor: '#fafafa', border: '1px solid #eaeaea', borderRadius: '0.2rem' }}>
							No result found
						</Menu.Item>
					)}
				</div>
			</Menu.Items>
		</Menu>
	);
};

export default PrColorPicker;
