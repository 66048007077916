import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { PaginationDto } from '../../../../../features/_common/dtos/paginations/pagination.dto';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CreateOrderStatuHistoryModal, { CreateOrderStatuHistoryModalData } from '../../../orderManagement/orders/modals/CreateOrderStatuHistoryModal';
import { OrderForListNewDto } from '../../../../../features/orders/dtos/order-for-list-new.dto';
import { Pagination } from '../../../../../models/_commons/responses/pagination';
import { OrderStatuTypes } from '../../../../../features/orders/dtos/order-statu-types';
import { DeliveryTypes } from '../../../../../features/orders/_common/create-manuel-order/create-manuel-order.request';
import { PaginationOrderByEnum } from '../../../../../features/_common/dtos/paginations/pagination.interface';
import { setForSellerOrderListPaginationInfo } from '../../../../../redux/features/pagination/paginationSlice';
import stringHelper from '../../../../../helpers/string.helper';
import toastHelper from '../../../../../helpers/toast.helper';
import PrDropdown from '../../../../../helpers/widgets/Printram/Dropdown/PrDropdown';
import styles from './CustomDtfListAdmin.module.scss';
import enumToArrayHelper from '../../../../../helpers/enum-to-array.helper';
import SearchInput from '../../../../../helpers/widgets/Inputs/SearchInput/SearchInput';
import { Checkbox } from 'primereact/checkbox';
import PrintDropdown from '../../../../../helpers/widgets/Order/PrintDropdown/PrintDropdown';
import { Tooltip } from 'primereact/tooltip';
import dateHelper from '../../../../../helpers/dateHelper';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CustomDtfOrderStatu } from '../../../../../features/customDtf/get-custom-dtf-orders/get-custom-dtf-orders.request';
import customDtfService from '../../../../../features/customDtf/custom-dtf.service';
import { CustomDtfOrder } from '../../../../../features/customDtf/get-custom-dtf-orders/get-custom-dtf-orders.response';
import { GetCustomDtfOrdersAdminRequest } from '../../../../../features/customDtf/get-custom-dtf-orders-admin/get-custom-dtf-orders-admin.request';
import CustomDtfOrderFilterTab from '../CustomDtfList/Components/CustomDtfOrderFilterTab/CustomDtfOrderFilterTab';
import { Dropdown } from 'primereact/dropdown';
import { CustomDtfOrderAdmin } from '../../../../../features/customDtf/get-custom-dtf-orders-admin/get-custom-dtf-orders-admin.response';
import { CreateOrderStatuHistoryRequest } from '../../../../../features/orders/derived-features/order-statu-histories/create-order-statu-history/create-order-statu-history.request';
import { CreateCustomDtfOrderStatuHistoryRequest } from '../../../../../features/customDtf/create-custom-dtf-order-statu-history/create-custom-dtf-order-statu-history.request';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PrMultipleCalendar from '../../../../../helpers/widgets/Printram/Calendar/PrMultipleCalendar';
import { Message } from 'primereact/message';
import CustomDtfOrderDetailModal from '../CustomDtfList/Components/CustomDtfOrderDetailModal/CustomDtfOrderDetailModal';
import CustomDtfAdminOrderDetailModal from './Components/CustomDtfAdminOrderDetailModal/CustomDtfAdminOrderDetailModal';
import { GetOrderDtfFilesRequest } from '../../../../../features/customDtf/get-order-dtf-files/get-order-dtf-files.request';

const CustomDtfList = () => {
	const user = useAppSelector((_state) => _state.auth.data?.user);
	const fromStatePagination = useAppSelector((_state) => _state.pagination.forSellerOrderListPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const searchStatus = searchParams.get('status');

	const [loading, setLoading] = useState<boolean>(false);
	const [statuLoading, setStatuLoading] = useState<boolean>(false);
	const [statuModalVisible, setStatuModalVisible] = useState<boolean>(false);
	const [statuAcknowledgeModalVisible, setStatuAcknowledgeModalVisible] = useState<boolean>(false);
	const [statuModalData, setStatuModalData] = useState<CreateOrderStatuHistoryModalData | undefined>(undefined);
	const [searchFilter, setSearchFilter] = useState<string>('');
	const [orders, setOrders] = useState<CustomDtfOrderAdmin[]>([]);
	const [selectedOrders, setSelectedOrders] = useState<CustomDtfOrderAdmin[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);
	const [filterOrderStatuType, setFilterOrderStatuType] = useState<CustomDtfOrderStatu | null>(!!searchStatus ? Number(searchStatus) || null : null);
	const [lastRequest, setLastRequest] = useState<GetCustomDtfOrdersAdminRequest>(new GetCustomDtfOrdersAdminRequest({}));
	const [serviceLabelNames, setServiceLabelNames] = useState<string[]>([]);
	const [selectedServiceLabelName, setSelectedServiceLabelName] = useState<string | null>(null);
	const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryTypes | null>(null);
	const [showOrderModal, setShowOrderModal] = useState<boolean>(false);
	const [orderToShow, setOrderToShow] = useState<CustomDtfOrderAdmin | undefined>(undefined);

	const getAll = async (request: Partial<GetCustomDtfOrdersAdminRequest>) => {
		request.orderStatu = filterOrderStatuType || undefined;
		request.contains = typeof request.contains !== 'undefined' ? request.contains : searchFilter || undefined;
		request.deliveryType = selectedDeliveryType || undefined;

		const descStatus = [null, 1, 2, 6, 7, 8, 12];

		const clonedRequest = new GetCustomDtfOrdersAdminRequest({ ...structuredClone(request), sellerId: user?.userId });

		clonedRequest.pagination!.orderBy = descStatus.includes(filterOrderStatuType) ? PaginationOrderByEnum.Descending : PaginationOrderByEnum.Ascending;

		try {
			setLoading(true);

			const response = await customDtfService.getCustomDtfOrdersAdmin(clonedRequest);

			if (!response.isSuccess || !response.data?.orders) throw '';

			setOrders(response.data.orders.data);
			setPaginationResponse(response.data.orders.pagination);

			/*			if (!request.labelServiceName && response.data.serviceLabelNames.length > 0) {
				setServiceLabelNames(response.data.serviceLabelNames);
			}*/

			const newPagination = JSON.parse(JSON.stringify(clonedRequest.pagination));

			dispatch(setForSellerOrderListPaginationInfo(newPagination));

			//	console.clear();
		} catch (error) {
			setOrders([]);
			setPaginationResponse(undefined);
		} finally {
			setSelectedOrders([]);
			setLoading(false);
			setLastRequest(clonedRequest);
		}
	};

	const updateCustomDtfOrderStatus = async (request: Partial<CreateCustomDtfOrderStatuHistoryRequest>) => {
		try {
			setStatuLoading(true);

			const response = await customDtfService.createOrderStatuHistory(new CreateCustomDtfOrderStatuHistoryRequest({ ...request }));

			if (!response.isSuccess) throw '';

			await getAll(lastRequest);
		} catch (error) {
		} finally {
			setStatuLoading(false);
		}
	};

	const downloadFile = async (orderId: string) => {
		try {
			setLoading(true);

			const response = await customDtfService.getOrderDtfFiles(new GetOrderDtfFilesRequest(orderId));

			if (!response.isSuccess || !response.data) throw '';

			window.location.href = response.data.url;
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const openOrderModal = (order: CustomDtfOrderAdmin) => {
		setOrderToShow(order);
		setShowOrderModal(true);
	};

	const hideOrderModal = () => {
		setShowOrderModal(false);
		setOrderToShow(undefined);
	};

	const selectAllHeaderTemplate = () => (
		<div className="flex flex-row align-items-center">
			<Checkbox className="mr-2" checked={selectedOrders.length === orders.length} onChange={(event) => setSelectedOrders(event.checked ? orders : [])} />
			<p className="m-0">Code</p>
		</div>
	);

	const selectAllTemplate = (_order: CustomDtfOrder) => (
		<div className="flex flex-row justify-content-start align-items-center">
			<Checkbox
				inputId={`selectItem${_order.id}`}
				name={`selectItem${_order.id}`}
				checked={!!selectedOrders.find((_sOrder) => _sOrder.id === _order.id)}
				className={'block mr-2'}
				onChange={(event) =>
					setSelectedOrders((_current) => {
						let newCurrent = structuredClone(_current);

						if (event.checked) newCurrent.push(_order);
						else newCurrent = newCurrent.filter((_nCurrent: CustomDtfOrder) => _nCurrent.id !== _order.id);

						return newCurrent;
					})
				}
			/>
			<div className="flex flex-column">
				<p className="m-0">{_order.code}</p>
				<p className="text-sm font-light m-0">{_order.files.length} items</p>
			</div>
		</div>
	);

	const storeTemplate = (_order: CustomDtfOrder) => (
		<div>
			<p className="font-semibold text-lg m-0">{_order.storeName}</p>
		</div>
	);

	const deliveryTemplate = (_order: CustomDtfOrder) => <span className={styles.deliveryType}>{DeliveryTypes[_order.deliveryType || 0]}</span>;

	const customerTemplate = (_order: CustomDtfOrder) => <p className="">{_order.customerFullName}</p>;

	const statusTemplate = (_order: CustomDtfOrderAdmin) => (
		<Dropdown
			className="custom-dropdown-prevent w-full"
			options={_order.availableOrderStatus}
			optionValue="id"
			optionLabel="readableName"
			placeholder={CustomDtfOrderStatu[_order.statu]}
			onChange={(event) => {
				updateCustomDtfOrderStatus({
					customDtfOrderId: _order.id,
					customDtfOrderStatuId: event.value
				});
			}}
			value={_order.statu}
		/>
	);

	const totalTemplate = (_order: CustomDtfOrder) => <p>{_order.orderTotal.formattedPricePerUnit.toFixed(2)} $</p>;

	const downloadTemplate = (_order: CustomDtfOrder) => (
		<div
			className="download-text-button-prevent pi pi-arrow-circle-down text-xl text-primary text-underline cursor-pointer"
			role="button"
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				downloadFile(_order.id);
			}} />
	);

	useEffect(() => {
		getAll({});

		const newLastRequest = structuredClone(lastRequest);

		newLastRequest.dateFilter.minDate = null;
		newLastRequest.dateFilter.maxDate = null;

		setLastRequest(newLastRequest);
	}, [searchStatus, selectedServiceLabelName, selectedDeliveryType]);

	return (
		<main className="">
			<section className="container-header">
				<h1 className="container-header-title">DTF Orders</h1>
			</section>

			<section className="container-body p-3 z-1">
				<h4 className="m-0">Filters</h4>
				<p className="text-color-secondary">You can perform all the filtering on the Order page from the options below.</p>

				<CustomDtfOrderFilterTab filterOrderStatuType={filterOrderStatuType} setFilterOrderStatuType={setFilterOrderStatuType} page="seller" />

				<div className="flex mt-4">
					<div className="flex-1">
						<h5 className="mb-2">Service Label Filter</h5>
						<PrDropdown
							clearable
							filter
							placeholder="Select Service Label"
							onClear={() => {
								setSelectedServiceLabelName(null);
							}}
							onChange={(e) => {
								setSelectedServiceLabelName(e.value as string);
							}}
							value={
								selectedServiceLabelName
									? {
											label: selectedServiceLabelName,
											value: selectedServiceLabelName
										}
									: undefined
							}
							options={serviceLabelNames.map((_serviceLabel) => ({
								label: _serviceLabel,
								value: _serviceLabel
							}))}
							className={styles.dropdownMinWidth}
						/>
					</div>

					<div className="flex-1">
						<h5 className="mb-2">Delivery Type Filter</h5>

						<PrDropdown
							clearable
							placeholder="Select Delivery Type"
							onClear={() => {
								setSelectedDeliveryType(null);
							}}
							onChange={(e) => {
								setSelectedDeliveryType(e.value as DeliveryTypes);
							}}
							value={
								selectedDeliveryType
									? {
											label: DeliveryTypes[selectedDeliveryType],
											value: selectedDeliveryType
										}
									: undefined
							}
							options={enumToArrayHelper.convertArray(DeliveryTypes, true)}
							className={styles.dropdownMinWidth}
						/>
					</div>
				</div>

				<div className="mt-4">
					<SearchInput onChange={(e) => setSearchFilter(e.target.value)} value={searchFilter} onKeyDown={(event) => event.key === 'Enter' && getAll({})} filterBtn disabled={loading} placeholder="Search orders (order code, customer name, tracking number, etsy receipt no)" filterBtnOnClick={() => getAll({})} />
				</div>

				<h5 className="mb-2">Date Filter</h5>
				<PrMultipleCalendar dates={lastRequest.dateFilter ?? undefined} onChange={(event) => getAll({ dateFilter: event })} />

				{filterOrderStatuType === CustomDtfOrderStatu.OrderApproved ? <Message severity="info" text="Orders are updated every 2 hours. If you can't view your order, please try again later." className="w-full justify-content-start gap-3 font-bold px-4 mt-3" /> : null}
			</section>

			<section className="container-body p-0 z-0 relative">
				<div className={styles.ordersTableBody}>
					<DataTable
						value={orders}
						onRowClick={(event) => {
							const target = event.originalEvent.target as HTMLElement;
							const isDropdown = target.closest('.custom-dropdown-prevent');
							const isDropdownItem = target.closest('.p-dropdown-item');
							const isDownload = target.closest('.download-text-button-prevent');

							if (!isDropdown && !isDropdownItem && !isDownload) {
								openOrderModal(event.data); // Only open the modal if it wasn't a dropdown click
							}
						}}>
						<Column field="" header={selectAllHeaderTemplate} body={selectAllTemplate} />
						<Column field="" header="Store" body={storeTemplate} />
						<Column field="" header="Delivery" body={deliveryTemplate} />
						<Column field="" header="Customer" body={customerTemplate} />
						<Column field="" header="Total" body={totalTemplate} />
						<Column field="" header="Status" body={statusTemplate} />
						<Column field="" header="Download Files" body={downloadTemplate} />
					</DataTable>

					{!!paginationResponse && paginationResponse.totalItemCount > 0 && orders.length > 0 ? (
						<div className={`${styles.ordersTableBodyItemWrapper} ${styles.loadMore}`}>
							<Paginator
								template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
								currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
								first={fromStatePagination.first}
								rows={fromStatePagination.itemCount || 20}
								totalRecords={paginationResponse.totalItemCount}
								rowsPerPageOptions={[10, 20, 30]}
								onPageChange={(event) => {
									if (fromStatePagination.pageNumber !== event.page + 1) setSelectedOrders([]);

									getAll({
										...lastRequest,
										pagination: {
											first: event.first,
											itemCount: event.rows,
											pageNumber: event.page + 1,
											orderBy: PaginationOrderByEnum.Descending
										}
									});
								}}
							/>
						</div>
					) : null}
				</div>
			</section>

			{loading || statuLoading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}

			{/*<CreateOrderStatuHistoryModal data={statuModalData} visible={statuModalVisible} setVisible={setStatuModalVisible} callback={updateCustomDtfOrderStatus} />*/}
			{/*<CreateOrderStatuHistoryAcknowledgeModal orderId={statuModalData?.orderId} visible={statuAcknowledgeModalVisible} setVisible={setStatuAcknowledgeModalVisible} callback={updateOrderStatus} />*!/*/}

			<CustomDtfAdminOrderDetailModal
				updateCustomDtfOrderStatus={(r) => {
					setShowOrderModal(false);
					updateCustomDtfOrderStatus(r);
				}}
				downloadFiles={downloadFile}
				show={showOrderModal}
				onHide={hideOrderModal}
				order={orderToShow}
			/>
		</main>
	);
};

export default CustomDtfList;
