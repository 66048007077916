import {useEffect} from "react";
import etsyLoggerService from "../services/etsy-logger-service";


const EtsyLogger = () => {

    useEffect(() => {
        etsyLoggerService.sendRequestToService();
    }, []);

    return (
        <h1></h1>
    );
};

export default EtsyLogger