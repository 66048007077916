import { FullPriceDto } from 'features/_common/dtos/prices/full-price.dto';
import { EtsyStore } from 'features/seller-stores/get-seller-stores-by-seller-id/dtos/etsy-store.dto';
import { Prices } from '../product/prices';
import { EtsyGiftInformation } from './etsy/etsy-gift-information';
import { EtsyOrder } from './etsy/etsy-order';
import { OrderAddress } from './order-address';
import { OrderItem } from './order-item';
import { OrderStatu } from './order-statu';
import { OrderStatuHistoryDto, OrderStatuHistoryOld } from './order-statu.history';
import { OrderStore } from './order-store';
import { OrderTransport } from './order-transport';
import { DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { OrderAvailableStatuDto } from 'features/orders/dtos/order-statu.dto';
import { NewPrice } from 'models/_commons/dtos/price/new-price';
import { MyProductMedia } from 'features/products/derived-features/product-copied-by-sellers/get-my-products-by-seller/get-my-products-by-seller.response';
import { Color, Size } from 'features/products/_common/get-core-product/get-core-product.response';

export enum PriceTypes {
	Unknown = 0,
	GrandTotal = 1,
	SubTotal = 2,

	// Is Used on Printram
	TotalPrice = 3,
	TotalShippingCost = 4,
	TotalTaxCost = 5,
	TotalVatCost = 6,
	DiscountAmount = 7,
	GiftWrapPrice = 8,

	// Is Used on Printram
	ItemsTotal = 9,

	// Is Used on Printram
	TransportPriceForPrintram = 10,

	// Is Used on Printram
	TransportPrice = 11,

	// Is Used on Printram
	EstimatedTransportPrice = 12,

	DtfPrintPrice = 13,

	ItemsTax = 14,
	// Is Used on Printram
	DiscountedItemsTotal = 15,
	// Is Used on Printram
	DiscountedShipping = 16,
	// Is Used on Printram
	DiscountedTotal = 17
}

export interface PrintramPriceDto {
	orderPriceId: string | null;
	price: FullPriceDto;
	type: PriceTypes;
}

export interface PrintramOrderPrice {
	orderId: string;
	printramOrderPriceId: string | null;
	isInstantCalculated: boolean;
	price: PrintramPriceDto;
}

export interface PrintramPrice {
	orderId: string;
	printramOrderPrices: PrintramOrderPrice[];
	etsyOrderPrices: [];
}

export interface OrderOld {
	id: string;
	sellerId: string;
	code: string;
	customerFullName: string;
	customerNote: string;
	externalGrantTotal: Prices;
	printramGrantTotal: Prices;
	createType: string;
	createdPlatform: string;
	isExternalOrder: boolean;
	statu: OrderStatu;
	createdDateAtPrintram: Date;
	etsyOrder?: EtsyOrder;
	orderAddress: OrderAddress;
	giftInformation: EtsyGiftInformation;
	orderItems: OrderItem[];
	orderStatuHistories: OrderStatuHistoryOld[];
	transport?: OrderTransport;
	store: OrderStore;
	prices: PrintramPrice;
}

export interface OrderAddressDto {
	id: string;
	orderId: string;
	receiverFullName: string;
	receiverPhoneNumber: string;
	receiverEmail: string;
	firstLine: string;
	secondLine: string;
	city: string;
	state: string;
	zip: string;
	formattedAddress: string;
	countryIso: string;
}

export interface OrderStoreDto {
	shopId: string;
	name: string;
	isConnectedAnyMarketPlace: boolean;
	etsyStore: EtsyStore;
}

export interface EtsyOrderDto {
	createdDateAtEtsy: Date;
	receiptId: number;
	shopId: number;
}

export interface OrderTicketDto {
	createdDate: Date;
	isActive: boolean;
	isAnswered: boolean;
	lastComment: string;
	lastCommentDate: Date;
	lastCommentOwnerFullName: string;
	ticketId: string;
	ticketCode: string;
	title: string;
}

export interface OrderAttachmentFile {
	id: string;
	orderId: string;
	filePath: string;
}

export interface OrderDto {
	id: string;
	code: string;
	customerFullName: string;
	customerNote: string;
	externalGrantTotal: number;
	printramGrantTotal: number;
	isManuelOrder: boolean;
	isExternalOrder: boolean;
	store: OrderStoreDto;
	giftInformation: EtsyGiftInformation;
	orderAddress: OrderAddressDto | null;
	transport: OrderTransport | null;
	statu: OrderStatu;
	prices: PrintramPrice;
	assignInfo: any | null;
	items: OrderItemDto[];
	statuHistories: OrderStatuHistoryDto[];
	deliveryType: DeliveryTypes | null;
	availableOrderStatus: OrderAvailableStatuDto[] | null;
	orderAttachmentFiles: OrderAttachmentFile[] | null;
	etsyOrder: EtsyOrderDto | null;
	ticket: OrderTicketDto | null;
	sellerNote: string | null;
	sellerFullName: string;
	storeName: string;
}

export interface PrintMethodAttributeForDetail {
	changedValue: string;
	name: string;
	printMethodAttributeId: string;
	value: string;
}

export interface OrderItemSideDto {
	productPrintSideId: string;
	printSideName: string;
	design: MyProductMedia;
	printPrice: NewPrice;
	methodPrice: NewPrice;
	templateMockup: MyProductMedia;
	printHeight: number;
	printWidth: number;
	printMethodAttributes: PrintMethodAttributeForDetail[];
	printMethodName: string;
	produtPrintSideMethodId: string;
}

export interface OrderItemDto {
	id: string;
	orderId: string;
	intraPriceId: string;
	coreProductId: string;
	variantId: string;
	code: string;
	coreProductName: string;
	categoryName: string;
	brandName: string;
	modelName: string;
	fullSku: string;
	personalization: string | null;
	sellerNote: string | null;
	price: Price;
	color: Color;
	size: Size;
	variantMedia: MyProductMedia;
	sides: OrderItemSideDto[];
}

export interface Price {
	pricePerUnit: number;
	formattedPricePerUnit: number;
}

export interface ImageWithStorage {
	path: string;
	urlPrefix: string;
	containerName: string;
	isEmpty: boolean;
	id: string;
	url: string;
	type: string;
}
