import React, { useEffect } from 'react';
import CustomDtfShippingInfo from './Components/CustomDtfShippingInfo';
import { CreateCustomDtfContext, CustomDtfContextType } from '../../context/CustomDtfContextProvider';
import { CustomDtfCostBreakdown } from './Components/CustomDtfCostBreakdown';
import PrButton from '../../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import CustomDtfShippingRates from './Components/CustomDtfShippingRates';
import { orderTransportService } from '../../../../../features/orders/derived-features/order-transports/order-transport.service';
import { GetShippingRatesRequest } from '../../../../../features/orders/derived-features/order-transports/get-shipping-rates/get-shipping-rates.request';
import { CreateManuelOrderDeliveryAddressRequestDto } from '../../../../../features/orders/_common/create-manuel-order/create-manuel-order.request';

export const CustomDtfShipSummary = () => {
	const context: CustomDtfContextType = React.useContext(CreateCustomDtfContext) as CustomDtfContextType;

	useEffect(() => {
		getShippingRates();
	}, []);

	const getShippingRates = async () => {
		try {
			context.setCalculatedPrice(0);
			const variantAndQuantities: { [id: string]: number } = {};
			variantAndQuantities[context.variant?.productVariantId!] = 1;
			const reqDeliveryAddress: CreateManuelOrderDeliveryAddressRequestDto = structuredClone(context.deliveryAddress);

			const request = new GetShippingRatesRequest({ variantAndQuantities: variantAndQuantities, shipTo: reqDeliveryAddress });

			const response = await orderTransportService.getShippingRates(request);

			if (!response.isSuccess || !response.data) throw '';

			context.setShippingRates(response.data!);

		} catch (error) {
		} finally {
		}
	};

	return (
		<React.Fragment>
			<div className="container-body p-5">
				<CustomDtfShippingInfo />
			</div>

			<div className="container-body p-5">
				<div className="tutorial-selector1">
					<CustomDtfShippingRates />
				</div>
			</div>

			{!!context.calculatedPrice ? (
				<div className="container-body text-center p-5">
					<CustomDtfCostBreakdown />

					<div className="flex justify-content-center mt-4">
						<PrButton text="Continue to review" onClick={() => context.nextStep()} />
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
};
