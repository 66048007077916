import { ShippingRateDto } from 'features/orders/derived-features/order-transports/dtos/shipping-rate.dto';
import { CreateManuelOrderDeliveryAddressRequestDto } from '../create-manuel-order/create-manuel-order.request';

export interface SideIdentitiesWithOtherFeatures {
	productPrintSideIdentity: string;
	productPrintSideMethodIdentity: string;
}
export interface CalculateItems {
	uniqueIdentifier: string;
	variantId: string;
	quantity: number;
	sideIdentitiesWithOtherFeatures: SideIdentitiesWithOtherFeatures[];
}

export class CalculateOrderPricesWithVariantsAndSidesRequest {
	shippingRate: ShippingRateDto | null;
	shipTo: CreateManuelOrderDeliveryAddressRequestDto | null;
	items: CalculateItems[];

	constructor(props: Partial<CalculateOrderPricesWithVariantsAndSidesRequest>) {
		this.shippingRate = props.shippingRate || null;
		this.shipTo = props.shipTo || null;
		this.items = props.items || [];
	}

	get getConvertUriExtension() {
		return `calculate-order-prices-with-variants-and-sides`;
	}
}
