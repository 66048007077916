export class SoftDeletePrintMethodRequest {
	id: string;

	constructor(id: string) {
		this.id = id;
	}

	get getConvertUriExtension() {
		return `soft-delete/${this.id}`
	}
}