export class CreatePrintMethodRequest {
	name: string;
	isActive: boolean;

	constructor(props: Partial<CreatePrintMethodRequest>) {
		this.name = props.name || "";
		this.isActive = props.isActive || true;
	}

	get getConvertUriExtension() {
		return 'create'
	}
}