import { Dialog } from 'primereact/dialog';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import printMethodsService from 'features/print-methods/_common/print-methods.service';
import { useState } from 'react';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { PrintMethodForListDto } from 'features/print-methods/dtos/print-method-for-list.dto';
import { UpdatePrintMethodRequest } from 'features/print-methods/_common/update/update.request';

type Props = {
	isVisible: boolean;
	setVisible: Function;
	getPrintMethods: Function;
	editedMethod: PrintMethodForListDto;
	setEditedMethod: Function;
};

const UpdatePrintMethod = ({ isVisible, setVisible, getPrintMethods, editedMethod, setEditedMethod }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);

	const initialValues: UpdatePrintMethodRequest = new UpdatePrintMethodRequest({ printMethodId: editedMethod.id, name: editedMethod.name, isActive: editedMethod.isActive });

	const validationScheme = Yup.object().shape({ name: Yup.string().required('Name is required').min(1, 'Name must be at least 1 characters.').max(200, 'Name must be a maximum of 200 characters.') });

	const submitForm = async (values: UpdatePrintMethodRequest) => {
		try {
			setLoading(true);

			const request = new UpdatePrintMethodRequest(values);

			const response = await printMethodsService.updatePrintMethod(request);

			if (!response.isSuccess) throw '';

			setVisible(false);
			setEditedMethod(undefined);
			getPrintMethods();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog
			visible={isVisible}
			header="Update Print Method"
			modal
			onHide={() => {
				setVisible(false);
				setEditedMethod(undefined);
			}}
			className="max-w-30rem">
			<Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
				{({ values, handleChange }) => (
					<Form>
						<div className="flex flex-row gap-4">
							<PrTextInput name="name" value={values.name} onChange={handleChange} placeholder="Enter print method name" label={''} />

							<div className="flex justify-content-end">
								<PrButton btnType="submit" type="secondary" text="Update" loading={loading} className="h-full px-4" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default UpdatePrintMethod;
