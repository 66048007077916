import { CoreProductSynchronizeDto } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import React, { useEffect, useState } from 'react';
import { SyncOrderContext, SyncOrderContextType, orderSteps } from '../../context/SyncOrderContextProvider';
import { useParams } from 'react-router-dom';
import { ExternalOrderDto } from 'features/orders/_common/get-external-order-by-id/get-external-order-by-id.response';
import orderService from 'features/orders/_common/order.service';
import { GetExternalOrderByIdRequest } from 'features/orders/_common/get-external-order-by-id/get-external-order-by-id.request';
import { ProductsTable } from './components/products-table/ProductsTable';
import { SyncEtsyProduct, SyncEtsySide } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/dtos/sync-etsy-product.dto';
import { SelectPrintramProductDialog } from 'components/_common/modals/select-printram-product/SelectPrintramProductDialog';
import { ConfirmSideWithDesign, SideWithDesignDialog } from 'components/_common/modals/side-with-design/SideWithDesignDialog';
import { useAppSelector } from 'redux/hooks';
import { User } from 'models/dtos/auth/users/user';
import { CreateEtsySynchronizeProductsWithSidesRequest } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/create-etsy-synchronize-products-with-sides.request';
import htmlToImageHelper from 'helpers/html-to-image.helper';
import stringHelper from 'helpers/string.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { NewPrice } from 'models/_commons/dtos/price/new-price';
import { confirmDialog } from 'primereact/confirmdialog';
import { Color, SalePrice, Size } from 'features/products/_common/get-core-product/get-core-product.response';
import { CalculateItems, CalculateOrderPricesWithVariantsAndSidesRequest } from 'features/orders/_common/calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.request';
import { FaChevronRight } from 'react-icons/fa';
import currencyHelper from 'helpers/curreny.helper';
import { CreateManuelOrderDeliveryAddressRequestDto } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { SyncOrderDeliveryAddressRequestDto } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/dtos/create-model.dto';
import { SelectPrintramProductDialogNew } from 'components/_common/modals/select-printram-product-new/SelectPrintramProductDialogNew';

interface SideWithPrice extends SyncEtsySide {
	printPrice: NewPrice | null;
}

export interface EtsyProductForSync extends SyncEtsyProduct {
	title: string;
	variations: { key: string; value: string }[];
	quantity: number;
	sidesWithPrice?: SideWithPrice[];
	selectedVariantPrice?: SalePrice | null;
	sku: string;
	product: CoreProductSynchronizeDto | null;
	selectedColor: Color | null;
	selectedSize: Size | null;
}

export const StepProducts = () => {
	const context = React.useContext(SyncOrderContext) as SyncOrderContextType;
	const { orderId } = useParams();
	const { userId } = useAppSelector((state) => state.auth.data?.user || ({} as User));

	const [selectedProduct, setSelectedProduct] = useState<CoreProductSynchronizeDto | undefined>(undefined);
	const [syncProduct, setSyncProduct] = useState<SyncEtsyProduct | undefined>();
	const [selectProductVisible, setSelectProductVisible] = useState<boolean>(false);
	const [sideWithDesignDialogVisible, setSideWithDesignDialogVisible] = useState<boolean>(false);

	const getExternalOrderDetail = async () => {
		try {
			context.setLoading(true);
			if (!orderId) throw '';

			const response = await orderService.getExternalOrderById(new GetExternalOrderByIdRequest(orderId));
			if (!response || !response.isSuccess || !response.data) throw '';

			const newExternalProducts: EtsyProductForSync[] = [];

			for (const externalProduct of response.data.externalProducts) {
				newExternalProducts.push({
					externalProductId: externalProduct.externalProductId,
					externalPrice: externalProduct.externalPrice,
					externalProductVariation: externalProduct.externalProductVariation,
					productId: '',
					productVariantId: '',
					product: null,
					sellerNote: null,
					sides: [],
					title: externalProduct.title,
					variations: externalProduct.externalProductVariation.variations,
					quantity: externalProduct.quantity,
					sku: externalProduct.etsyTransaction.sku,
					selectedColor: null,
					selectedSize: null
				});
			}
			context.setExternalProducts(newExternalProducts);
			context.setSelectedStore(response.data.store);

			context.setRequest((_current) => {
				const newRequest = structuredClone(_current);

				newRequest.createModel.customerNote = response.data?.etsyOrder['message_from_buyer'] || null;
				newRequest.createModel.sellerNote = response.data?.etsyOrder['message_from_seller'] || null;

				if (!!response.data?.etsyOrder['gift_message']) newRequest.createModel.giftMessage = response.data?.etsyOrder['gift_message'];

				const orderAddress = response.data?.orderAddress;

				if (!!orderAddress) {
					newRequest.createModel.shipTo = new SyncOrderDeliveryAddressRequestDto({
						id: orderAddress.id,
						orderId: orderAddress.orderId,
						city: orderAddress?.city.trim(),
						countryIso: orderAddress?.countryIso.trim(),
						firstLine: orderAddress?.firstLine.trim(),
						secondLine: orderAddress?.secondLine?.trim(),
						formattedAddress: orderAddress?.formattedAddress.replace(`${orderAddress.receiverFullName} `, '').trim(),
						receiverEmail: orderAddress?.receiverEmail.trim(),
						receiverFullName: orderAddress?.receiverFullName.trim(),
						state: orderAddress?.state.trim(),
						vatNumber: orderAddress?.vatNumber?.trim(),
						zip: orderAddress?.zip.trim()
						// phonePrefix: !!orderAddress?.receiverPhoneNumber ? { value: orderAddress.receiverPhoneNumber.trim().slice(0, orderAddress.receiverPhoneNumber.trim().length - 10), label: orderAddress.receiverPhoneNumber.trim().slice(0, orderAddress.receiverPhoneNumber.trim().length - 10) } : undefined,
						// receiverPhoneNumber: orderAddress?.receiverPhoneNumber ? orderAddress.receiverPhoneNumber.trim().slice(-10) : undefined
					});

					const phoneNumber = orderAddress.receiverPhoneNumber;

					if (phoneNumber) {
						newRequest.createModel.shipTo.phonePrefix = { label: phoneNumber.slice(0, phoneNumber.length - 10), value: phoneNumber.slice(0, phoneNumber.length - 10) };
						newRequest.createModel.shipTo.receiverPhoneNumber = phoneNumber.slice(-10);
					}
				}

				console.log({ orderAddress, shipTo: newRequest.createModel.shipTo });

				newRequest.createModel.products = newExternalProducts;

				return newRequest;
			});

			console.clear();
		} catch (error) {
			context.setExternalProducts([]);
		} finally {
			context.setLoading(false);
		}
	};

	const calculateTotal = async (externalProducts: EtsyProductForSync[]) => {
		try {
			context.setLoading(true);

			const items: CalculateItems[] = externalProducts.map((_product) => ({ uniqueIdentifier: _product.externalProductId, variantId: _product.productVariantId, sideIdentitiesWithOtherFeatures: _product.sides.map((_side) => ({ productPrintSideIdentity: _side.productPrintSideId || '', productPrintSideMethodIdentity: _side.productPrintSideMethodId || '' })), quantity: _product.quantity }));

			const response = await orderService.calculateOrderPricesWithVariantsAndSidesResponse(
				new CalculateOrderPricesWithVariantsAndSidesRequest({
					shippingRate: null,
					shipTo: null,
					items: items.filter((_item) => _item.sideIdentitiesWithOtherFeatures.length > 0)
				})
			);

			if (!response.isSuccess || !response.data) throw '';

			context.setCalculatedPrice(response.data);
		} catch (error) {
		} finally {
			context.setLoading(false);
		}
	};

	const showIsOrderHaveProductWithoutSidesDialog = (callback: Function) => {
		confirmDialog({
			header: 'Order Sync Confirmation',
			icon: 'pi pi-info-circle',
			message: 'Some items in the order were not synchronized. These products will not be included in the order. Are you sure you want to continue?',
			acceptLabel: 'Continue',
			rejectLabel: 'Cancel',
			acceptClassName: 'px-4',
			rejectClassName: 'p-button-text px-4',
			accept: () => callback()
		});
	};

	useEffect(() => {
		if (!syncProduct) return;

		setSelectProductVisible(true);
	}, [syncProduct]);

	useEffect(() => {
		if (context.orderStep.value !== 1) return;

		orderSteps[0].isCanNext = context.calculatedPrice ? true : false;

		context.setOrderStep({ ...orderSteps[0] });
	}, [context.calculatedPrice]);

	useEffect(() => {
		if (!orderId || orderSteps[0].isCanNext) return;

		getExternalOrderDetail();

		context.setRequest((_current) => {
			const newRequest = structuredClone(_current);

			newRequest.createModel.orderId = orderId;
			newRequest.createModel.sellerId = userId;

			return newRequest;
		});
	}, [orderId]);

	return (
		<React.Fragment>
			<div className="container-body p-4" style={{ zIndex: 'auto' }}>
				<ProductsTable syncAction={setSyncProduct} />
			</div>

			<div className="container-body p-3 bottom-0 left-0 right-0" style={{ height: '5.5rem' }}>
				<div className="flex align-items-center justify-content-end h-full gap-3">
					{context.calculatedPrice ? (
						<div>
							<h6 className="m-0 text-right">{currencyHelper.formatPrice(context.calculatedPrice?.fulfillment?.formattedPricePerUnit || context.calculatedPrice?.fullfillment?.formattedPricePerUnit || 1)}</h6>
							{/* <div className="text-sm m-0">+ Shipment</div> */}
						</div>
					) : null}

					<PrButton
						text="Continue"
						disabled={!context.orderStep.isCanNext}
						onClick={() => {
							if (!context.orderStep.isCanNext) return;

							const complete = () => {
								orderSteps[0].isCompleted = true;

								context.setIsShowShippingForm(true);
								context.setIsNewShippingRatesCalculateRequired(true);
								context.setOrderStep(orderSteps[1]);
							};

							if (context.request.createModel.products.length !== context.request.createModel.products.filter((_prod) => _prod.sides.length > 0).length) {
								showIsOrderHaveProductWithoutSidesDialog(complete);
								return;
							}

							complete();
						}}
						icon={<FaChevronRight />}
					/>
				</div>
			</div>

			<SelectPrintramProductDialogNew
				visible={selectProductVisible}
				setVisible={setSelectProductVisible}
				syncProduct={syncProduct}
				productId={undefined}
				handleProductClick={(_product) => {
					setSelectedProduct(_product);

					if (!sideWithDesignDialogVisible && !!syncProduct) setSideWithDesignDialogVisible(true);
				}}
			/>

			{/* <SelectPrintramProductDialog
				visible={selectProductVisible}
				setVisible={setSelectProductVisible}
				setSyncProduct={setSyncProduct}
				syncProduct={syncProduct}
				onlySelectProduct
				productId={undefined}
				handleProductClick={(_product) => {
					if (!_product) return;

					setSelectedProduct(_product);

					if (!sideWithDesignDialogVisible) setSideWithDesignDialogVisible(true);
				}}
			/> */}

			{!!selectedProduct ? (
				<SideWithDesignDialog
					visible={sideWithDesignDialogVisible}
					product={selectProductVisible ? undefined : selectedProduct}
					setVisible={setSideWithDesignDialogVisible}
					onChangeProduct={() => setSelectProductVisible(true)}
					setSyncProduct={setSyncProduct}
					syncProduct={syncProduct}
					onConfirm={async (event) => {
						try {
							console.clear();

							const request = structuredClone(context.request);

							const externalProducts = structuredClone(context.externalProducts);

							for (const product of request.createModel.products) {
								if (product.externalProductId !== syncProduct?.externalProductId) continue;

								product.productId = event.product.id;
								product.productVariantId = event.variant.id;
								product.sides = event.sides.map(
									(_eventSide) =>
										({
											productPrintSideId: _eventSide.id,
											productPrintSideMethodId: _eventSide.productPrintSideMethodId,
											name: _eventSide.name,
											designFile: _eventSide.designFile,
											printWidth: Math.round((_eventSide.printInfo?.printWidth || 0) * 10) / 10,
											printHeight: Math.round((_eventSide.printInfo?.printHeight || 0) * 10) / 10,
											templateMockupFile: htmlToImageHelper.dataUrlToFile(_eventSide.mockupBase64!, `${stringHelper.slugify(_eventSide.name)}-mockup.png`),
											printMethodAttributes: _eventSide.printMethodAttributes
										} as SyncEtsySide)
								);

								break;
							}

							for (const externalProduct of externalProducts) {
								if (externalProduct.externalProductId !== syncProduct?.externalProductId) continue;

								const product = request.createModel.products.find((_product: SyncEtsyProduct) => _product.externalProductId === externalProduct.externalProductId);

								if (!product) continue;

								externalProduct.productId = product.productId;
								externalProduct.productVariantId = product.productVariantId;
								externalProduct.product = event.product;
								externalProduct.sides = product.sides;
								externalProduct.selectedColor = event.color;
								externalProduct.selectedSize = event.size;

								break;
							}

							context.setRequest(request);

							context.setExternalProducts(externalProducts);
							await calculateTotal(externalProducts);

							setSyncProduct(undefined);
							setSelectedProduct(undefined);
						} catch (error) {}
					}}
				/>
			) : null}
		</React.Fragment>
	);
};
