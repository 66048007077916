import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import PrButton from '../../../helpers/widgets/Printram/Forms/Buttons/PrButton';

const CustomDtf = () => {
	const location = useLocation();
	const basePathName = '/custom-dtf/';

	return (
		<main className="">
			<section className="container-header">

				<div className="container-header-tools">
					{location.pathname === basePathName ? (
						<PrButton type="primary" disabled text="Create DTF Order" />
					) : (
						<Link to={basePathName}>
							<PrButton type="secondary" text="Create DTF Order" />
						</Link>
					)}

					{location.pathname === `${basePathName}operation-step-list` ? (
						<PrButton type="primary" disabled text="Operation Step List" />
					) : (
						<Link to={`${basePathName}orders`}>
							<PrButton type="secondary" text="Orders" />
						</Link>
					)}
				</div>

			</section>

			<Outlet />
		</main>
	);
};

export default CustomDtf;
