export class GetEmbroideryImageRequest {
	designFile: File | undefined;
	oldColor?: string;
	newColor?: string;

	constructor(props: Partial<GetEmbroideryImageRequest>) {
		this.designFile = props.designFile;
		if (props.oldColor) this.oldColor = props.oldColor;
		if (props.newColor) this.newColor = props.newColor;
	}

	get getConvertUriExtension() {
		return 'get-embroidery-image';
	}
}
