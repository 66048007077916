import { DeleteMethodAttributeRequest } from 'features/print-methods/_common/delete-method-attribute/delete-method-attribute.request';
import { GetMethodsAttributeByTypeRequest } from 'features/print-methods/_common/get-method-attribute-by-type/get-method-attribute-by-type.request';
import printMethodsService from 'features/print-methods/_common/print-methods.service';
import { PrintMethodAttributeDto } from 'features/print-methods/dtos/print-method-attribute.dto';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UpsertPrintMethodAttribute from '../components/UpsertPrintMethodAttribute';

const PrintMethodAttributes = () => {
	const { printMethodId } = useParams();

	const [attributes, setAttributes] = useState<PrintMethodAttributeDto[]>([]);
	const [editedAttribute, setEditedAttribute] = useState<PrintMethodAttributeDto | undefined>(undefined);
	const [upsertVisible, setUpsertVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const handleEdit = (attribute: PrintMethodAttributeDto) => {
		setEditedAttribute(attribute);
		setUpsertVisible(true);
	};

	const actionsTemplate = (attribute: PrintMethodAttributeDto) => (
		<div className="flex gap-2 justify-content">
			<Button onClick={() => handleEdit(attribute)} label="Edit" icon="pi pi-fw pi-file-edit" className="p-button-info" />
			<Button onClick={() => deleteMethod(attribute.id)} label="Delete" icon="pi pi-fw pi-trash" className="p-button-danger" />
		</div>
	);

	const deleteMethod = async (attributeId: string) => {
		if (!attributeId) return;

		try {
			setLoading(true);

			const request = new DeleteMethodAttributeRequest(attributeId);

			const response = await printMethodsService.deletePrintMethodAttribute(request);

			if (!response) throw '';

			getAttributes();
		} catch (error) {
			setLoading(false);
		} finally {
		}
	};

	const getAttributes = async () => {
		if (!printMethodId) return;

		try {
			setLoading(true);

			const request = new GetMethodsAttributeByTypeRequest({ printMethodId: printMethodId, attributeType: 1 });

			const response = await printMethodsService.getPrintMethodAttributes(request);

			if (!response.isSuccess) throw '';

			setAttributes(response.data || []);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getAttributes();
	}, []);

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">Print Method Attributes</h1>

				<div className="container-header-tools">
					<PrButton text="New Attribute" type="secondary" onClick={() => setUpsertVisible(true)} icon={<span className="pi pi-fw pi-plus" />} />
				</div>
			</div>

			<div className="container-body p-0">
				<DataTable value={attributes} dataKey="id" className="p-datatable-gridlines">
					<Column field="name" header="Attribute Name" />
					<Column field="value" header="Attribute Value" />
					<Column field="actions" header="Actions" body={actionsTemplate} style={{ width: '0' }} />
				</DataTable>
			</div>

			<UpsertPrintMethodAttribute isVisible={upsertVisible} setVisible={setUpsertVisible} getAttributes={getAttributes} editedAttribute={editedAttribute || undefined} setEditedAttribute={setEditedAttribute || undefined} printMethodId={printMethodId || ''} />

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default PrintMethodAttributes;
