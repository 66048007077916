import { AreaSelector, IArea } from '@bmunozg/react-image-area';
import { Size } from 'features/products/_common/get-core-product/get-core-product.response';
import { CreateProductPrintSideRequest, PrintArea, SideTypeEnum } from 'features/products/derived-features/product-print-sides/create-product-print-side/create-product-print-side.request';
import { DeleteProductPrintSideRequest } from 'features/products/derived-features/product-print-sides/delete-product-print-side/delete-product-print-side.request';
import { GetProductPrintSidesRequest } from 'features/products/derived-features/product-print-sides/get-product-print-sides/get-product-print-sides.request';
import { ProductPrintSide } from 'features/products/derived-features/product-print-sides/get-product-print-sides/get-product-print-sides.response';
import { productPrintSideService } from 'features/products/derived-features/product-print-sides/product-print-side.service';
import { GetSizesByProductRequest } from 'features/products/derived-features/product-variants/get-sizes-by-product/get-sizes-by-product.request';
import productVariantService from 'features/products/derived-features/product-variants/product-variant.service';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import currencyHelper from 'helpers/curreny.helper';
import enumToArrayHelper from 'helpers/enum-to-array.helper';
import mediaHelper from 'helpers/media.helper';
import toastHelper from 'helpers/toast.helper';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrPriceInput from 'helpers/widgets/Printram/Forms/Input/PrPriceInput';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import ApplyMultipleDialog from './components/ApplyMultipleDialog';
import EditSide from './components/EditSide';
import { GetPrintMethodsRequest } from 'features/print-methods/_common/get-print-methods/get-print-methods.request';
import printMethodsService from 'features/print-methods/_common/print-methods.service';
import { PrintMethodForListDto } from 'features/print-methods/dtos/print-method-for-list.dto';

export const printSideTypeOptions: PrDropdownType[] = enumToArrayHelper.convertArray(SideTypeEnum, true);

const AddSide = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const fileInputRef = useRef<HTMLInputElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const formikRef = useRef<FormikProps<CreateProductPrintSideRequest>>(null);
	const [areas, setAreas] = useState<IArea[]>([]);
	const [multiplier, setMultiplier] = useState<number>(5);

	const initialValues = new CreateProductPrintSideRequest({ productId: id, isActive: true });

	const [getLoading, setGetLoading] = useState<boolean>(false);
	const [sizeLoading, setSizeLoading] = useState<boolean>(false);
	const [createLoading, setCreateLoading] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [getPrintMethodsLoading, setGetPrintMethodsLoading] = useState<boolean>(false);
	const [productSides, setProductSides] = useState<ProductPrintSide[]>([]);
	const [productSizes, setProductSizes] = useState<Size[]>([]);
	const [printMethods, setPrintMethods] = useState<PrintMethodForListDto[]>([]);
	const [activePrintMethods, setActivePrintMethods] = useState<Array<string>>([]);
	const [applyMultipleVisible, setApplyMultipleVisible] = useState<boolean>(false);
	const [editedSide, setEditedSide] = useState<ProductPrintSide | undefined>(undefined);
	const [editVisible, setEditVisible] = useState<boolean>(false);

	const actionsTemplate = (side: ProductPrintSide) => {
		return (
			<div className="flex flex-row gap-2 max-w-6rem">
				<PrButton text="Edit" onClick={() => setEditedSide(side)} type="secondary" />
				<PrButton text="Delete" onClick={() => deleteSide(side.id)} type="danger" loading={deleteLoading} />
			</div>
		);
	};

	const getPrintMethods = async () => {
		try {
			setGetPrintMethodsLoading(true);

			const request = new GetPrintMethodsRequest();

			const response = await printMethodsService.getPrintMethods(request);

			if (!response.isSuccess || !response.data) throw '';

			if (formikRef.current) formikRef.current.values.printMethods = response.data.map((_data) => ({ printMethodId: _data.id, amount: 0, isDeleted: false }));
			setPrintMethods(response.data);
			setActivePrintMethods(response.data.map((_data) => _data.id));
		} catch (error) {
			setPrintMethods([]);
		} finally {
			setGetPrintMethodsLoading(false);
		}
	};

	const getSizes = async () => {
		try {
			setSizeLoading(true);

			const response = await productVariantService.getSizesByProduct(new GetSizesByProductRequest(id as string));
			if (!response.isSuccess || !response.data) throw '';

			setProductSizes(response.data);
			initialValues.initSizes(response.data);
		} catch (error) {
			setProductSizes([]);
		} finally {
			setSizeLoading(false);
		}
	};

	const getSides = async () => {
		try {
			setGetLoading(true);

			if (!id) throw '';

			const response = await productPrintSideService.getSides(new GetProductPrintSidesRequest(id));
			if (!response.isSuccess || !response.data) throw '';

			setProductSides(response.data);

			console.clear();
		} catch (error) {
			setProductSides([]);
		} finally {
			setGetLoading(false);
		}
	};

	const deleteSide = async (sideId: string) => {
		try {
			setDeleteLoading(true);

			const request = new DeleteProductPrintSideRequest(sideId);

			const response = await productPrintSideService.deleteSide(request);

			if (!response) throw '';

			getSides();
			getSizes();
		} catch (error) {
		} finally {
			setDeleteLoading(false);
		}
	};

	useEffect(() => {
		if (!editedSide) return;

		setEditVisible(true);
	}, [editedSide]);

	useEffect(() => {
		getSides();
		getSizes();
		getPrintMethods();
	}, [id]);

	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">Print Sides</h1>

				<div className="container-header-tools">{window.history.length > 1 && <PrButton text="Go Back" onClick={() => navigate(-1)} type="secondary" icon={<span className="pi pi-fw pi-arrow-left" />} />}</div>
			</div>

			<div className="container-body p-3">
				<h4 className="m-0">Current Sides</h4>
				<p className="text-sm text-600">The table below lists the available printing sides for the product.</p>

				<DataTable value={productSides} className="pr-table" showGridlines>
					<Column header="Preview" field="medias[0].media" style={{ width: '90px' }} align={'center'} body={(row: ProductPrintSide) => <img loading="lazy" src={mediaHelper.getImage(row.medias[0].media)} width={60} height={60} style={{ objectFit: 'contain' }} />} />
					<Column header="Name" field="name" />
					<Column header="Media Height" field="medias[0].mediaHeight" body={(row: ProductPrintSide) => <span>{row.medias[0].mediaHeight.toFixed(2)} px</span>} />
					<Column header="Media Width" field="medias[0].mediaWidth" body={(row: ProductPrintSide) => <span>{row.medias[0].mediaWidth.toFixed(2)} px</span>} />
					<Column header="Price" field="printPrice.formattedPricePerUnit" body={(row: ProductPrintSide) => currencyHelper.formatPrice(row.printPrice.formattedPricePerUnit)} />
					<Column header="Order" field="order" />
					<Column header="Active" body={(row: ProductPrintSide) => <input type="checkbox" checked={row.isActive} disabled style={{ height: 15, width: 15 }} />} />
					<Column body={actionsTemplate} />
				</DataTable>
			</div>

			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				validationSchema={Yup.object().shape({
					name: Yup.string().required('Name is required'),
					amount: Yup.number().required('Price is required').min(1, 'Price must be greater than or equal to 1'),
					templateMockupImage: Yup.mixed().required('Side Mockup Image is required, please select side image')
				})}
				onSubmit={async (values, formikHelpers) => {
					try {
						setCreateLoading(true);

						if (!imageRef.current) {
							toastHelper.warning('Mockup side image not found');
							throw '';
						}

						if (areas.length < 1) {
							toastHelper.warning('Please select an area on image');
							throw '';
						}

						const clonedRequest = new CreateProductPrintSideRequest({
							...structuredClone(values),
							mediaWidth: +imageRef.current.getBoundingClientRect().width.toFixed(2),
							mediaHeight: +imageRef.current.getBoundingClientRect().height.toFixed(2),
							order: values.order || productSides.length + 1,
							area: new PrintArea({
								width: +areas[0].width.toFixed(2),
								height: +areas[0].height.toFixed(2),
								xCoordinate: +areas[0].x.toFixed(2),
								yCoordinate: +areas[0].y.toFixed(2)
							}),
							printDimensions: values.printDimensions.map((_dimension) => ({
								sizeId: _dimension.sizeId,
								minPrintSizeWidth: 2,
								minPrintSizeHeight: 2,
								maxPrintSizeWidth: _dimension.defaultPrintSizeWidth,
								maxPrintSizeHeight: _dimension.defaultPrintSizeHeight,
								defaultPrintSizeWidth: _dimension.defaultPrintSizeWidth,
								defaultPrintSizeHeight: _dimension.defaultPrintSizeHeight
							})),
							printMethods: values.printMethods.filter((_method) => activePrintMethods.includes(_method.printMethodId))
						});

						clonedRequest.amount = Math.round((clonedRequest.amount || 0) * 100);
						clonedRequest.printMethods = clonedRequest.printMethods.map((_method) => ({ ..._method, amount: Math.round((_method.amount || 0) * 100) }));

						const response = await productPrintSideService.createOrUpdate(clonedRequest);
						if (!response.isSuccess) throw '';

						formikHelpers.resetForm();
						setAreas([]);
						await getSides();
					} catch (error) {
					} finally {
						setCreateLoading(false);
					}
				}}>
				{(form) => (
					<Form className="container-body p-3">
						<div className="flex flex-row justify-content-between mb-4">
							<div>
								<h4 className="m-0">Add New Side</h4>
								<p className="text-sm text-600">To add new side to the product, please fill out the form below.</p>
							</div>

							<PrButton text="Multiply Save" onClick={() => setApplyMultipleVisible(true)} btnType="button" type="secondary" />
						</div>

						<div className="flex gap-3 mb-3">
							<div className="flex-1 flex flex-column gap-1">
								<PrTextInput label="Name" value={form.values.name} onChange={form.handleChange} name="name" required placeholder="Enter side name" />
								<ErrorMessage name="name" component="small" className="text-red" />
							</div>

							<div className="flex-1 flex flex-column gap-1">
								<PrPriceInput label="Price" name="amount" required value={form.values.amount} onChange={(event) => form.setFieldValue('amount', event.value)} placeholder="Enter side price" />
								<ErrorMessage name="amount" component="small" className="text-red" />
							</div>

							<div className="flex-1 flex flex-column gap-1">
								<label className="block font-bold">Type *</label>
								<PrDropdown options={printSideTypeOptions} value={printSideTypeOptions.find((_option) => form.values.type === _option.value)} onChange={(event) => form.setFieldValue('type', event.value)} className="w-full mt-auto" />
							</div>
						</div>

						<div className="flex flex-column gap-3 mb-4">
							{form.values.printDimensions.map((_dimension, index) => (
								<div key={index} className="flex flex-wrap gap-2 pt-3 border-top-1 border-300">
									<div className="flex-1 flex gap-1 align-items-center justify-content-center uppercase font-bold">
										<span>{productSizes.find((_size) => _size.id === _dimension.sizeId)?.name}</span>
									</div>

									<div className="flex-1 flex-column gap-1">
										<PrTextInput label="Width" name={`printDimensions[${index}].defaultPrintSizeWidth`} required value={_dimension.defaultPrintSizeWidth} onChange={form.handleChange} placeholder="Printarea default width" />
										<ErrorMessage name={`printDimensions[${index}].defaultPrintSizeWidth`} component="small" className="text-red" />
									</div>

									<div className="flex-1 flex-column gap-1">
										<PrTextInput label="Height" name={`printDimensions[${index}].defaultPrintSizeHeight`} required value={_dimension.defaultPrintSizeHeight} onChange={form.handleChange} placeholder="Printarea default width" />
										<ErrorMessage name={`printDimensions[${index}].defaultPrintSizeHeight`} component="small" className="text-red" />
									</div>
								</div>
							))}
						</div>

						<div className="flex flex-column gap-3 py-4 border-top-1 border-300">
							<div>
								<input
									ref={fileInputRef}
									type="file"
									className="hidden"
									accept=".png, .webp"
									value={''}
									onChange={(e) => {
										if (!e.target.files || e.target.files.length < 1) return;

										const dimensions = form.values.printDimensions[0];

										if (!dimensions || !dimensions.defaultPrintSizeWidth || !dimensions.defaultPrintSizeHeight) {
											toastHelper.warning('Add Width / Height first');
											return;
										}

										setAreas([{ x: 0, y: 0, width: dimensions.defaultPrintSizeWidth * multiplier, height: dimensions.defaultPrintSizeHeight * multiplier, unit: 'px' }]);

										form.setFieldValue('templateMockupImage', e.target.files?.[0]);
									}}
								/>

								<PrButton btnType="button" type="secondary" disabled={!form.values.printDimensions[0]?.defaultPrintSizeWidth || !form.values.printDimensions[0]?.defaultPrintSizeHeight} onClick={() => fileInputRef.current?.click()} text="Browse Print Mockup File" icon={<span className="pi pi-fw pi-plus-circle" />} />
							</div>

							{!!form.values.templateMockupImage ? (
								<div className="flex gap-4">
									<AreaSelector
										areas={areas}
										onChange={(_areas) => {
											const currentArea = areas[0];
											const eventArea = _areas[0];

											if (currentArea.width === eventArea.width && currentArea.height === eventArea.height) setAreas(_areas);
										}}
										maxAreas={1}
										globalAreaStyle={{ backgroundColor: 'white', opacity: 0.5, border: '1.5px dashed' }}>
										<img ref={imageRef} src={URL.createObjectURL(form.values.templateMockupImage)} alt="Selected Mockup Side Image" className="block" style={{ maxHeight: '400px', maxWidth: '400px' }} />
									</AreaSelector>

									<div>
										<PrTextInput
											label="Multiplier"
											name="multiplier"
											required
											type="number"
											placeholder="Enter Multiplier"
											value={multiplier}
											onChange={(e) => {
												const value = +e.target.value;
												const dimensions = form.values.printDimensions[0];

												if (!dimensions || !dimensions.defaultPrintSizeWidth || !dimensions.defaultPrintSizeHeight) {
													toastHelper.warning('Add Width / Height first');
													return;
												}

												setMultiplier(value);

												setAreas((_areas) => {
													const clonedAreas = [..._areas];

													for (const clonedArea of clonedAreas) {
														clonedArea.width = dimensions.defaultPrintSizeWidth! * value;
														clonedArea.height = dimensions.defaultPrintSizeHeight! * value;
													}

													return clonedAreas;
												});
											}}
										/>

										<p className="mt-4 mb-2">
											Area Width = <b>{areas[0].width}px</b>
										</p>
										<p className="m-0">
											Area Height = <b>{areas[0].height}px</b>
										</p>
									</div>
								</div>
							) : null}
						</div>

						{printMethods ? (
							<div>
								<hr />
								<h5>Print Methods</h5>
								<div className="flex flex-wrap gap-2">
									{printMethods.map((_method, index) => (
										<div className="border-1 border-200 p-2">
											<Checkbox
												checked={activePrintMethods.find((_active) => _active === _method.id) ? true : false}
												onChange={(e) => {
													if (e.checked && !activePrintMethods.find((_active) => _active === _method.id)) {
														setActivePrintMethods([...activePrintMethods, _method.id]);
													} else if (!e.checked && activePrintMethods.find((_active) => _active === _method.id)) {
														setActivePrintMethods(activePrintMethods.filter((_active) => _active !== _method.id));
													}
												}}
											/>
											<span className="ml-1 font-bold">{_method.name}</span>
											<div className="mt-1">Price</div>
											<PrPriceInput name={`printMethods[${index}].amount`} value={form.values.printMethods[index].amount} onChange={(event) => form.setFieldValue(`printMethods[${index}].amount`, event.value)} disabled={getPrintMethodsLoading} placeholder="Enter side price" />
										</div>
									))}
								</div>
							</div>
						) : null}

						<hr />
						<div className="flex justify-content-between align-items-center gap-5">
							<div>
								<Checkbox name="isActive" inputId="isActive" checked={form.values.isActive} onChange={form.handleChange} />
								<label htmlFor="isActive" className="ml-2 font-bold">
									Is Side Active ?
								</label>
							</div>
							<div className="max-w-30rem">
								<PrTextInput label="Order No" name="order" required value={form.values.order} onChange={form.handleChange} placeholder="Order no" />
							</div>

							<PrButton btnType="submit" text="Save" disabled={getLoading || createLoading} />
						</div>
					</Form>
				)}
			</Formik>

			<ApplyMultipleDialog isVisible={applyMultipleVisible} setVisible={setApplyMultipleVisible} formikRef={formikRef} productSizes={productSizes} formikPrintDimensions={formikRef.current?.values.printDimensions || []} />

			<EditSide
				isVisible={editVisible}
				setVisible={setEditVisible}
				side={editedSide}
				setSide={setEditedSide}
				getAll={() => {
					getSides();
					getSizes();
				}}
				productSides={productSides}
				productSizes={productSizes}
				printMethods={printMethods}
			/>
			<PrLoading loading={getLoading || createLoading || sizeLoading} />
		</div>
	);
};

export default AddSide;
