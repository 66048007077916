import { GetPrintMethodsRequest } from 'features/print-methods/_common/get-print-methods/get-print-methods.request';
import printMethodsService from 'features/print-methods/_common/print-methods.service';
import { SoftDeletePrintMethodRequest } from 'features/print-methods/_common/soft-delete/soft-delete.request';
import { PrintMethodForListDto } from 'features/print-methods/dtos/print-method-for-list.dto';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import CreatePrintMethod from './components/CreatePrintMethod';
import UpdatePrintMethod from './components/UpdatePrintMethod';
import { useNavigate } from 'react-router-dom';

const PrintMethods = () => {
	const navigate = useNavigate();

	const [printMethods, setPrintMethods] = useState<PrintMethodForListDto[]>([]);
	const [editedMethod, setEditedMethod] = useState<PrintMethodForListDto>();
	const [editVisible, setEditVisible] = useState<boolean>(false);
	const [createVisible, setCreateVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const handleEdit = (method: PrintMethodForListDto) => {
		setEditedMethod(method);
		setEditVisible(true);
	};

	const actionsTemplate = (method: PrintMethodForListDto) => (
		<div className="flex gap-2 justify-content">
			<Button onClick={() => handleEdit(method)} label="Edit" icon="pi pi-fw pi-file-edit" className="p-button-info" />
			<Button onClick={() => navigate(`attributes/${method.id}`)} label="Attributes" icon="pi pi-fw pi-book" className="p-button-warning" />
			<Button onClick={() => deleteMethod(method.id)} label="Delete" icon="pi pi-fw pi-trash" className="p-button-danger" />
		</div>
	);

	const deleteMethod = async (methodId: string) => {
		if (!methodId) return;

		try {
			setLoading(true);

			const request = new SoftDeletePrintMethodRequest(methodId);

			const response = await printMethodsService.deletePrintMethod(request);

			if (!response) throw '';

			getPrintMethods();
		} catch (error) {
			setLoading(false);
		} finally {
		}
	};

	const getPrintMethods = async () => {
		try {
			setLoading(true);

			const request = new GetPrintMethodsRequest();

			const response = await printMethodsService.getPrintMethods(request);

			if (!response.isSuccess) throw '';

			setPrintMethods(response.data || []);
		} catch (error) {
			setPrintMethods([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPrintMethods();
	}, []);
	return (
		<div className="container relative">
			<div className="container-header">
				<h1 className="container-header-title">Print Methods</h1>

				<div className="container-header-tools">
					<PrButton text="New Print Method" type="secondary" onClick={() => setCreateVisible(true)} icon={<span className="pi pi-fw pi-plus" />} />
				</div>
			</div>

			<div className="container-body p-0">
				<DataTable value={printMethods} dataKey="id" className="p-datatable-gridlines">
					<Column field="name" header="Print Method Name" />
					<Column field="actions" header="Actions" body={actionsTemplate} style={{ width: '0' }} />
				</DataTable>
			</div>

			<CreatePrintMethod isVisible={createVisible} setVisible={setCreateVisible} getPrintMethods={getPrintMethods} />
			{editedMethod && <UpdatePrintMethod isVisible={editVisible} setVisible={setEditVisible} getPrintMethods={getPrintMethods} editedMethod={editedMethod} setEditedMethod={setEditedMethod} />}

			{loading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default PrintMethods;
