const isBiggerThenMb = (file: File, mb: number = 10): boolean => {
	if (!file) throw new Error('File not found');

	return file.size / (1024 * 1024) > mb;
};

const urlToFile = async (url: string) => {
	return fetch(url)
		.then((res) => res.blob())
		.then((myBlob) => {
			return new File([myBlob], 'printram.png', { type: myBlob.type });
		});
};

export const fileHelper = { isBiggerThenMb, urlToFile };
