import { AttributeType } from 'features/print-methods/enums/attribute-type.enum';

export class GetMethodsAttributeByTypeRequest {
	printMethodId: string;
	attributeType: AttributeType;

	constructor(props: Partial<GetMethodsAttributeByTypeRequest>) {
		this.printMethodId = props.printMethodId || '';
		this.attributeType = props.attributeType || 1;
	}

	get getConvertUriExtension() {
		return 'get-method-attribute-by-type';
	}
}
