export class DeleteMethodAttributeRequest {
	id: string;

	constructor(id: string) {
		this.id = id;
	}

	get getConvertUriExtension() {
		return `delete-method-attribute/${this.id}`;
	}
}
