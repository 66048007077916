import { Dialog } from 'primereact/dialog';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CreatePrintMethodRequest } from 'features/print-methods/_common/create/create.request';
import printMethodsService from 'features/print-methods/_common/print-methods.service';
import { useState } from 'react';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';

type Props = {
	isVisible: boolean;
	setVisible: Function;
	getPrintMethods: Function;
};

const CreatePrintMethod = ({ isVisible, setVisible, getPrintMethods }: Props) => {
	const [loading, setLoading] = useState<boolean>(false)

	const initialValues: CreatePrintMethodRequest = new CreatePrintMethodRequest({ name: '', isActive: true });

	const validationScheme = Yup.object().shape({ name: Yup.string().required('Name is required').min(1, 'Name must be at least 1 characters.').max(200, 'Name must be a maximum of 200 characters.') });

	const submitForm = async(values: CreatePrintMethodRequest) => {
		try {
			setLoading(true)

			const request = new CreatePrintMethodRequest(values)

			const response = await printMethodsService.createPrintMethod(request)

			if (!response.isSuccess) throw ''

			setVisible(false)
			getPrintMethods()
		} catch (error) {

		} finally { setLoading(false) }
	};

	return (
		<Dialog visible={isVisible} header="New Print Method" modal onHide={() => setVisible(false)} className='max-w-30rem'>
			<Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationScheme}>
				{({ values, handleChange }) => (
					<Form>
						<div className='flex flex-row gap-4'>
							<PrTextInput name="name" value={values.name} onChange={handleChange} placeholder="Enter print method name" label={''} />

							<div className="flex justify-content-end">
								<PrButton btnType="submit" type="secondary" text="Create" loading={loading} className="h-full px-4" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default CreatePrintMethod;
