export class UpdatePrintMethodRequest {
	printMethodId: string;
	name: string;
	isActive: boolean;

	constructor(props: Partial<UpdatePrintMethodRequest>) {
		this.printMethodId = props.printMethodId || "";
		this.name = props.name || '';
		this.isActive = props.isActive || true;
	}

	get getConvertUriExtension() {
		return 'update';
	}
}