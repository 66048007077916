import HttpBaseService from 'services/_common/http-base-service';
import { GetPrintMethodsRequest } from './get-print-methods/get-print-methods.request';
import { GetPrintMethodsResponse } from './get-print-methods/get-print-methods.response';
import { CreatePrintMethodRequest } from './create/create.request';
import { CreatePrintMethodResponse } from './create/create.response';
import { UpdatePrintMethodRequest } from './update/update.request';
import { UpdatePrintMethodResponse } from './update/update.response';
import { SoftDeletePrintMethodRequest } from './soft-delete/soft-delete.request';
import { GetMethodsAttributeByTypeRequest } from './get-method-attribute-by-type/get-method-attribute-by-type.request';
import { GetMethodsAttributeByTypeResponse } from './get-method-attribute-by-type/get-method-attribute-by-type.response';
import { UpsertPrintMethodAttributeRequest } from './upsert-print-method-attribute/upsert-print-method-attribute.request';
import { UpsertPrintMethodAttributeResponse } from './upsert-print-method-attribute/upsert-print-method-attribute.response';
import { DeleteMethodAttributeRequest } from './delete-method-attribute/delete-method-attribute.request';
import { GetEmbroideryImageRequest } from './get-embroidery-image/get-embroidery-image.request';
import { GetEmbroideryImageResponse } from './get-embroidery-image/get-embroidery-image.response';

class PrintMethodsService extends HttpBaseService {
	constructor() {
		super('print-methods');
	}

	async getPrintMethods(request: GetPrintMethodsRequest): Promise<GetPrintMethodsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async createPrintMethod(request: CreatePrintMethodRequest): Promise<CreatePrintMethodResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async updatePrintMethod(request: UpdatePrintMethodRequest): Promise<UpdatePrintMethodResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async deletePrintMethod(request: SoftDeletePrintMethodRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension);
	}

	async getPrintMethodAttributes(request: GetMethodsAttributeByTypeRequest): Promise<GetMethodsAttributeByTypeResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async upsertPrintMethodAttribute(request: UpsertPrintMethodAttributeRequest): Promise<UpsertPrintMethodAttributeResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async deletePrintMethodAttribute(request: DeleteMethodAttributeRequest): Promise<boolean> {
		return await this.deleteData(request.getConvertUriExtension);
	}

	async getEmbroideryImage(request: GetEmbroideryImageRequest): Promise<GetEmbroideryImageResponse> {
		return await this.postData(request.getConvertUriExtension, request, true);
	}
}

export default new PrintMethodsService();
