import { CreateOrderContext, CreateOrderContextType } from 'components/pages/orders/create-order/context/CreateOrderContextPrvider';
import { CalculateItems, CalculateOrderPricesWithVariantsAndSidesRequest } from 'features/orders/_common/calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.request';
import orderService from 'features/orders/_common/order.service';
import currencyHelper from 'helpers/curreny.helper';
import { RadioButton } from 'primereact/radiobutton';
import style from './ShippingRates.module.scss';
import React from 'react';

const ShippingRates = () => {
	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;

	return (
		<div>
			<div className={style.warningMessage}>If the cargo company calculates a different weight for the order, the difference will be charged.</div>

			<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
				Shipping method
			</h2>
			<div className={style.ratesWrapper}>
				{context.shippingRates.map((_rate, index) => (
					<div key={index} className="field-radiobutton">
						<RadioButton
							inputId={`rate${_rate.rateId || _rate.serviceName}`}
							value={_rate.rateId || _rate.serviceName}
							name="rate"
							onChange={async () => {
								try {
									context.setLoading(true);

									if (!context.request.deliveryAddress) return;

									const rateRequest = new CalculateOrderPricesWithVariantsAndSidesRequest({
										shippingRate: _rate,
										shipTo: structuredClone(context.request.deliveryAddress),
										items: context.request.items.map(
											(_item) =>
												({
													uniqueIdentifier: _item.uniqueId,
													variantId: _item.productVariantId,
													quantity: _item.quantity,
													sideIdentitiesWithOtherFeatures: _item.sides.map((_side) => ({ productPrintSideIdentity: _side.productPrintSideId || '', productPrintSideMethodIdentity: _side.productPrintSideMethodId || '' }))
												} as CalculateItems)
										)
									});

									if (!!rateRequest?.shipTo?.receiverPhoneNumber) rateRequest.shipTo.receiverPhoneNumber = rateRequest.shipTo.phonePrefix.value + rateRequest.shipTo.receiverPhoneNumber;
									if (rateRequest.shipTo?.receiverPhoneNumber === '') rateRequest.shipTo.receiverPhoneNumber = null;

									const response = await orderService.calculateOrderPricesWithVariantsAndSidesResponse(rateRequest);
									if (!response.data || !response.isSuccess) throw '';

									context.setSelectedRate(_rate);
									context.setCalculatedPrice(response.data);
								} catch (error) {
								} finally {
									context.setLoading(false);
								}
							}}
							checked={context.selectedRate?.rateId ? _rate.rateId === context.selectedRate?.rateId : _rate.serviceName === context.selectedRate?.serviceName}
						/>

						<label htmlFor={`rate${_rate.rateId || _rate.serviceName}`}>
							{currencyHelper.formatPrice(+_rate.amount)} - {_rate.serviceName}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default ShippingRates;
