import { Dialog } from 'primereact/dialog';
import { CustomDtfFile, CustomDtfOrder } from '../../../../../../../features/customDtf/get-custom-dtf-orders/get-custom-dtf-orders.response';
import React, { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import stringHelper from '../../../../../../../helpers/string.helper';
import { CustomDtfOrderStatu } from '../../../../../../../features/customDtf/get-custom-dtf-orders/get-custom-dtf-orders.request';
import { DeliveryTypes } from '../../../../../../../features/orders/_common/create-manuel-order/create-manuel-order.request';
import ShippingInfo from '../../../../../../../helpers/widgets/Order/ShippingInfo';
import { Dropdown } from 'primereact/dropdown';
import { CreateCustomDtfOrderStatuHistoryRequest } from '../../../../../../../features/customDtf/create-custom-dtf-order-statu-history/create-custom-dtf-order-statu-history.request';
import {
	AvaliableCustomDtfOrderStatus
} from '../../../../../../../features/customDtf/get-custom-dtf-orders-admin/get-custom-dtf-orders-admin.response';
import styles from "./CustomDtfOrderDetailModal.module.scss";
import {OrderStatuTypes} from "../../../../../../../features/orders/dtos/order-statu-types";
import PrButton from "../../../../../../../helpers/widgets/Printram/Forms/Buttons/PrButton";
import {BsBrush} from "react-icons/bs";
import PrDropdown from "../../../../../../../helpers/widgets/Printram/Dropdown/PrDropdown";

type Props = {
	onHide: () => void;
	order: CustomDtfOrder | undefined;
	updateCustomDtfOrderStatus: (request: Partial<CreateCustomDtfOrderStatuHistoryRequest>) => void;
	downloadFiles: (orderId: string) => void;
	show: boolean;
};

const CustomDtfOrderDetailModal = ({ onHide, order, updateCustomDtfOrderStatus, downloadFiles, show }: Props) => {
	const [_order, setOrder] = React.useState<CustomDtfOrder | undefined>();

	useEffect(() => {
		setOrder(order);
	}, [order]);


	const fileNameTemplate = (file: CustomDtfFile) => <p>{file.fileName}</p>;

	const quantityTemplate = (file: CustomDtfFile) => <p>{file.quantity}</p>;

	const heightTemplate = (file: CustomDtfFile) => <p>{file.height.toFixed(2)}</p>;

	const widthTemplate = (file: CustomDtfFile) => <p>{file.width.toFixed(2)}</p>;

	const downloadTemplate = (file: CustomDtfFile) => <div className='pi pi-arrow-circle-down cursor-pointer text-xl' role='link' onClick={() => window.location.href = file.fileUrl} />;

	return (
		<Dialog visible={show} style={{ width: '100%' }} header={_order?.code ?? ''} onHide={onHide}>
			<div>
				<section className="flex flex-column w-full mb-3">
					<div className="flex flex-row w-full">
						<div className='flex flex-column mr-auto'>
							<h4 className="m-0">Details</h4>
							<p className="text-sm text-600">The order details are provided below.</p>
						</div>
						<PrButton className='bg-transparent border-primary text-primary hover:bg-gray-100' text='Download Files' onClick={() => {
							onHide();
							downloadFiles(_order!.id)
						}}/>
					</div>

					<div className={styles.detailWrapper}>
						<div className={styles.detailItem}>
							<div className={styles.detailItemHeader}>
								<h6>Customer Name</h6>
							</div>

							<p>{order?.customerFullName || '-'}</p>
						</div>

						<div className={styles.detailItem}>
							<div className={styles.detailItemHeader}>
								<h6>Seller Full Name</h6>
							</div>

							<p>{order?.sellerFullName || '-'}</p>
						</div>

						<div className={styles.detailItem}>
							<div className={styles.detailItemHeader}>
								<h6>Store Name</h6>
							</div>

							<p>{order?.storeName || '-'}</p>
						</div>

						<div className={styles.detailItem}>
							<div className={styles.detailItemHeader}>
								<h6>Seller Note</h6>
							</div>

							<p>{order?.note || '-'}</p>
						</div>

						<div className={styles.detailItem}>
							<div className={styles.detailItemHeader}>
								<h6>Delivery Type / Order Type</h6>
							</div>

							<span>{DeliveryTypes[order?.deliveryType || 0]} / </span>
							{/*<span>{order !== null ? (order?.isManuelOrder ? 'Manuel' : order?.isExternalOrder ? 'External' : 'Automatic') : '-'}</span>*/}
						</div>

						<div className={styles.detailItem}>
							<div className={styles.detailItemHeader}>
								<h6>Platform</h6>
							</div>

							<p>{'Printram'}</p>
						</div>

						<div className={styles.detailItem}>
							<div className={styles.detailItemHeader}>
								<h6>Shipping Address</h6>

									<PrButton
										icon={<BsBrush/>}
										type="icon"
										id="shippingAddressUpdate"
										tooltip="Update shipping address"
										className="text-primary"
										onClick={() => {
											return;
										}}
									/>
							</div>

							<address>{order?.orderAddress?.formattedAddress || '-'}</address>
						</div>

						<div className={styles.detailItem}>
							<div className={styles.detailItemHeader}>
								<h6>Status</h6>
							</div>

							<PrDropdown
								className="w-full z-100"
								disabled={!order?.availableOrderStatus || order.availableOrderStatus.length < 1}
								value={{ value: '', label: stringHelper.parseAtUpperCaseAndJoin(CustomDtfOrderStatu[order?.statu || 0] || '') }}
								options={order?.availableOrderStatus?.map((_status) => ({ label: stringHelper.parseAtUpperCaseAndJoin(_status.readableName), value: _status.id }))}
								onChange={(event) => {
									updateCustomDtfOrderStatus({
										customDtfOrderId: _order!.id,
										customDtfOrderStatuId: Number(event.value)
									});
								}}
							/>
						</div>
					</div>
				</section>

				<section className="container-body p-3 z-1">
					<h3>Files</h3>
					<DataTable value={_order?.files}>
						<Column header="File Name" body={fileNameTemplate}></Column>
						<Column header="Quantity" body={quantityTemplate}></Column>
						<Column header="Height" body={heightTemplate}></Column>
						<Column header="Width" body={widthTemplate}></Column>
						<Column header="Download" body={downloadTemplate}></Column>
					</DataTable>
				</section>

				<div className="container-body flex flex-row w-full mb-3 p-1">
					<div className="flex-1"/>
					<div className="flex-1 flex flex-column align-items-stretch p-3 z-1">
						<div className="flex flex-row w-full border-bottom-1">
							<p className="font-semibold text-lg mr-auto m-0">Items total:</p>
							<p className='m-0 font-bold'>
								{_order?.itemsTotal.currency.symbol}
								{_order?.itemsTotal.formattedPricePerUnit.toFixed(2)}
							</p>
						</div>
						<div className="flex flex-row w-full border-bottom-1">
							<p className="font-semibold text-lg mr-auto m-0">Shipping total:</p>
							<p className='m-0 font-bold'>
								{_order?.shipmentPrice.currency.symbol}
								{_order?.shipmentPrice.formattedPricePerUnit.toFixed(2)}
							</p>
						</div>
						<div className="flex flex-row w-full">
							<p className="font-semibold text-lg mr-auto m-0">Grand total:</p>
							<p className='m-0 font-bold'>
								{_order?.orderTotal.currency.symbol}
								{_order?.orderTotal.formattedPricePerUnit.toFixed(2)}
							</p>
						</div>
					</div>
				</div>

				<div className="container-body p-3 z-1">
					<h3>Shipping Info</h3>

					{!!order && !!order.transport ? (
						<ShippingInfo {...order.transport} histories={[]}/>
					) : (
						<div
							className="h-7rem flex align-items-center justify-content-center"
							style={{
								backgroundColor: '#fafafa',
								border: '1px solid #eaeaea',
								borderRadius: '0.2rem'
							}}>
							<h5 className="m-0 text-color-secondary">
								{!!order && order.statu === CustomDtfOrderStatu.OrderApproved
									? "In order to view the transport information, the order's status needs to be changed to 'Order Approved'."
									: !!order?.orderAttachmentFiles && order.orderAttachmentFiles.length > 0
										? order.orderAttachmentFiles.map((_attachment, index) => (
											<a key={index} href={_attachment.filePath} target="_blank"
											   className="text-primary">
												{index + 1}. Open label
											</a>
										))
										: 'Shipping Information not found'}
							</h5>
						</div>
					)}
				</div>
			</div>
		</Dialog>
	);
};

export default CustomDtfOrderDetailModal;


/*
					<div className="flex flex-row w-full">
						<div className="mr-2 p-1 container-body flex-1">
							<h5 className="m-0">Items Total</h5>
							<p className="m-0 font-semibold">
								{_order?.itemsTotal.formattedPricePerUnit.toFixed(2)}
								{_order?.itemsTotal.currency.symbol}
							</p>
						</div>
						<div className="mr-2 p-1 container-body flex-1">
							<h5 className="m-0">Shipment Total</h5>
							<p className="m-0 font-semibold">
								{_order?.shipmentPrice.formattedPricePerUnit.toFixed(2)}
								{_order?.shipmentPrice.currency.symbol}
							</p>
						</div>
						<div className="mr-2 p-1 container-body flex-1">
							<h5 className="m-0">Order Total</h5>
							<p className="m-0 font-semibold">
								{_order?.orderTotal.formattedPricePerUnit.toFixed(2)}
								{_order?.orderTotal.currency.symbol}
							</p>
						</div>
						<div className="p-1 container-body flex-1">
							<h5 className="m-0">Customer Full Name</h5>
							<p className="m-0 font-semibold">{_order?.customerFullName}</p>
						</div>
					</div>
					<div className="flex flex-row w-full">
						<div className="mr-2 p-1 container-body flex-1">
							<h5 className="m-0">Order Status</h5>
							<Dropdown
								options={[{id: 6, type: 6, readableName: 'Cancel'}]}
								optionValue="id"
								optionLabel="readableName"
								placeholder={CustomDtfOrderStatu[_order?.statu || 0]}
								onChange={(event) => {
									updateCustomDtfOrderStatus({
										customDtfOrderId: _order!.id,
										customDtfOrderStatuId: event.value
									});
								}}
								value={_order?.statu || CustomDtfOrderStatu.Unknown}
								className="w-full"
							/>
						</div>
						<div className="mr-2 p-1 container-body flex-1">
							<h5 className="m-0">Store Name</h5>
							<p className="m-0 font-semibold">{_order?.storeName}</p>
						</div>
						<div className="mr-2 p-1 container-body flex-1">
							<h5 className="m-0">Seller</h5>
							<p className="m-0 font-semibold">{_order?.sellerFullName}</p>
						</div>
						<div className="p-1 container-body flex-1">
							<h5 className="m-0">Delivery Type</h5>
							<p className="m-0 font-semibold">{stringHelper.parseAtUpperCaseAndJoin(DeliveryTypes[_order?.deliveryType || 0])}</p>
						</div>
					</div>
*/
