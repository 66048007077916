import styles from './CustomDtfProductsTable.module.scss';
import React from 'react';
import { CreateCustomDtfContext, CustomDtfContextType } from '../../../../context/CustomDtfContextProvider';
import { Link } from 'react-router-dom';

const CustomDtfProductsTable = () => {
	const context: CustomDtfContextType = React.useContext(CreateCustomDtfContext) as CustomDtfContextType;

	return (
		<div>
			<table className={styles.table}>
				<thead>
					<tr>
						<th>Product</th>
						<th>Print File</th>
						<th>QTY</th>
						<th>Retail Each</th>
						<th>Total Price</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{context.itemDetails.map((item, index) => (
						<tr>
							<td>{context.variant?.productName}</td>
							<td>
								{item.file.name}
								<br />
								{(item.file.size / (1024 * 1024)).toFixed(2)}Mb
								<br />
								{item.length.toFixed(2)} inch
							</td>
							<td>{item.quantity}</td>
							<td>{`${context.variant?.pricePerInches.formattedPricePerUnit}`}$</td>
							<td>{item.total.toFixed(2)}$</td>
							<td><div className={'text-primary text-underline cursor-pointer'} role='button' onClick={() => context.setStep(0)}>Edit</div></td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default CustomDtfProductsTable;
