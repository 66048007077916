import { RadioButton } from 'primereact/radiobutton';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './CustomDtfOrder.module.scss';
import * as ExifReader from 'exifreader';
import { DtfProduct } from '../../../../../features/customDtf/get-products/get-dtf-products.response';
import { GetDtfProductsRequest } from '../../../../../features/customDtf/get-products/get-dtf-products.request';
import CustomDtfService from '../../../../../features/customDtf/custom-dtf.service';
import ItemRowField from './components/ItemRowField/ItemRowField';
import PrButton from '../../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import { CustomDtfFile } from '../../../../../features/customDtf/create-custom-dtf-order/create-custom-dtf-order.request';
import { CreateCustomDtfContext, CustomDtfContextType, ItemDetail } from '../../context/CustomDtfContextProvider';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import {confirmDialog} from "primereact/confirmdialog";
import {BiError} from "react-icons/bi";

const CustomDtfOrder = () => {
	const context: CustomDtfContextType = React.useContext(CreateCustomDtfContext) as CustomDtfContextType;
	const fileInputRef = useRef<HTMLInputElement>(null);

	const [products, setProducts] = useState<DtfProduct[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<DtfProduct | null>(null);
	const [uploading, setUploading] = useState<boolean>(false);
	const [uploaded, setUploaded] = useState<boolean>(false);
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState<string>('');

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;

		if (uploading) {
			interval = setInterval(() => {
				setProgress((prevProgress) => {
					if (prevProgress >= 90) {
						clearInterval(interval!); // Stop increasing when close to 100%
						return prevProgress;
					}
					return prevProgress + 10; // Increment progress
				});
			}, 1500); // Progress every 500ms
		} else if (!uploading && progress === 100) {
			setProgress(0); // Reset the progress when loading completes
		}

		return () => clearInterval(interval!); // Cleanup on component unmount or when loading stops
	}, [uploading]);

	useEffect(() => {
		getProducts();
	}, []);

	useEffect(() => {
		const newGrandTotal = context.itemDetails.reduce((total, current) => total + current.total, 0);
		context.setGrandTotal(newGrandTotal);
	}, [context.itemDetails]);

	const onSubmit = () => {
		if (context.itemDetails.length === 0) return;
		context.setVariant(selectedProduct!);
		context.submitItems(context.itemDetails.map((item, index) => new CustomDtfFile(item.file.name, item.fileKey, item.height, item.length, item.quantity)));
		context.nextStep();
	};

	const getProducts = async () => {
		try {
			const response = await CustomDtfService.getProducts(new GetDtfProductsRequest());

			if (!response.isSuccess || !response.data) throw '';

			setProducts(response.data);
			setSelectedProduct(response.data[0]);
		} catch (error) {
		} finally {
		}
	};

	const handleButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const onFilesChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const newFiles = e.target.files;
		if (newFiles) {
			const newItemPromises = Array.from(newFiles).map(async (file: File) => {
				const r = await getLength(file);
				const len = r!.len;
				const height = r!.height;

				if (height > 22.9) return setError('Height must be smaller than 22 inch');
				if (!len) return;

				return {
					file: file,
					fileKey: `${Date.now()}-${file.name}`,
					quantity: 1,
					length: len,
					height: height,
					total: len * selectedProduct?.pricePerInches.formattedPricePerUnit!
				};
			});
			const resolvedItems = await Promise.all(newItemPromises);

			const newItems = resolvedItems.filter((item) => item !== null) as ItemDetail[];

			context.setItemDetails((prevItems) => [...prevItems, ...Array.from(newItems)]);
		}
	};

	const onDeleteItem = (index: number) => {
		const newItems = context.itemDetails.filter((item, i) => i !== index);
		context.setItemDetails(newItems);

		context.setGrandTotal(context.itemDetails.reduce((total, current) => total + current.total, 0));
	};

	const handleDrop = async (event: React.DragEvent<HTMLLabelElement>) => {
		const newFiles = event.dataTransfer.files;
		event.preventDefault();

		if (newFiles) {
			const newItemPromises = Array.from(newFiles).map(async (file: File) => {
				const r = await getLength(file);
				const len = r!.len;
				const height = r!.height;
				if (!len) return;
				return {
					file: file,
					fileKey: `${Date.now()}-${file.name}`,
					quantity: 1,
					length: len,
					height: height,
					total: len * selectedProduct?.pricePerInches.formattedPricePerUnit!
				};
			});
			const resolvedItems = await Promise.all(newItemPromises);

			const newItems = resolvedItems.filter((item) => item !== null) as ItemDetail[];

			context.setItemDetails((prevItems) => [...prevItems, ...Array.from(newItems)]);
		}

		context.setGrandTotal(context.itemDetails.reduce((total, current) => total + current.total, 0));
	};

	const onQuantityChange = (index: number, value: number) => {
		if (!value || value < 0) return;

		const newItems = context.itemDetails.map((item, i) => {
			if (i === index) {
				return {
					...item,
					quantity: value,
					total: item.length * selectedProduct?.pricePerInches.formattedPricePerUnit! * value
				};
			}
			return item;
		});

		context.setItemDetails(newItems);
	};

	const showFailDialog = (error: any) => {
		confirmDialog({
			header: 'There was an error while uploading files',
			icon: <BiError color="red" size={50} />,
			message: 'Please try again later...',
			acceptLabel: 'Ok',
			acceptClassName: 'px-4 bg-blue-600',
			rejectClassName: 'hidden',
			className: 'max-w-30rem',
			accept: () => {}
		});
	};

	const uploadFile = async () => {
		try {
			setUploading(true);
			setProgress(0);

			const s3Client = new S3Client({
				region: 'us-east-1',
				credentials: {
					accessKeyId: 'AKIAZ4NEVOK6J26OFLN7',
					secretAccessKey: '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'
				}
			});

			const promiseList: Promise<any>[] = context.itemDetails.map((item, index) => {
				return s3Client.send(
					new PutObjectCommand({
						Bucket: 'homfoodie',
						Key: `printram/images/${item.fileKey}`,
						Body: context.itemDetails[0].file
					})
				);
			});
			const response = await Promise.all(promiseList);

			if (response) setUploaded(true);
		} catch (error) {
			showFailDialog(error);
		} finally {
			setUploading(false);
		}
	};

	const getLength = async (file: File): Promise<{ height: number; len: number } | undefined> => {
		try {
			const tags = await ExifReader.load(file);
			const height = tags['Image Height']?.value;
			const width = tags['Image Width']?.value;
			const ppuX = tags['Pixels Per Unit X']?.value;
			const ppuY = tags['Pixels Per Unit Y']?.value;
			const lenX = (width! / ppuX!) * 39.3701;
			const lenY = (height! / ppuY!) * 39.3701;
			const h = lenX <= lenY ? lenX : lenY;
			const len = lenX > lenY ? lenX : lenY;

			if (h > 22.9) return;

			return { height: h, len: len };
		} catch (e) {
		} finally {
		}
	};

	return (
		<div className="relative bg-white border-round-md p-3 text-sm">
			<div className={'flex flex-row justify-content-start align-items-center my-2'}>
				<div className={'flex flex-column justify-content-center align-items-start'}>
					<p className={'m-0 mb-2'}>Product:</p>
					{products.map((product, index) => (
						<div key={index} className={'flex flex-row justify-content-center align-items-center'}>
							<RadioButton inputId={'dtf-22'} value={'DTF-22'} checked={product.productVariantId === selectedProduct?.productVariantId} onChange={(e) => setSelectedProduct(product)} />
							<label className={'ml-2'} htmlFor={'dtf-22'}>
								{product.productName}
							</label>
						</div>
					))}
				</div>
			</div>

			<div className={'flex flex-column justify-content-center align-items-center'}>
				<p className={'m-0 mb-2 mr-auto'}>File(s):</p>
				<label tabIndex={-1} htmlFor="dtfFile" className={`${styles.printFileWrapper}`} onDragOver={(e) => e.preventDefault()} onDragLeave={(e) => e.preventDefault()} onDrop={handleDrop}>
					<input type="file" value={''} accept={'.tiff, .png'} name="dtfFile" id="dtfFile" className="hidden" ref={fileInputRef} onChange={onFilesChange} />
					{context.itemDetails.length === 0 ? (
						<span className={`text-center ${styles.printFileText}`}>
							Drag and drop files here...  <br /> (or click to select files) <br /> (png, pdf, or tiff)
						</span>
					) : (
						<div className={styles.printFileCards}>
							{context.itemDetails.map((item, index) => (
								<div key={index} className={`px-1 ${styles.printFileCardsCard}`}>
									<div className={styles.printFileCardsCardContent}>{uploading ? <ProgressSpinner strokeWidth={'5px'} style={{ width: '3rem' }} className={''} /> : <div className={'pi pi-file text-5xl text-primary'} />}</div>
									<div className={styles.printFileCardsCardFooter}>
										<p>{item.file.name}</p>
										<p>{(item.file.size / (1024 * 1024)).toFixed(2)} MB</p>
									</div>
									<div
										className={styles.printFileCardsCardDelete}
										role="button"
										onClick={(event) => {
											event.preventDefault();
											onDeleteItem(index);
										}}>
										<div className={'pi pi-fw pi-trash'}></div>
									</div>
								</div>
							))}
						</div>
					)}
				</label>
				{error !== '' && (
					<div className="w-full bg-red-500">
						<p>{error}</p>
					</div>
				)}
				<div className={'flex flex-row justify-content-center w-full align-items-center mb-2'}>
					<div className={'mr-auto w-3'}>{uploading && <ProgressBar value={progress} style={{ height: '1rem', borderRadius: '3px' }} color={'#10B981'}></ProgressBar>}</div>
					<PrButton className={'ml-2 mt-2 bg-transparent border-primary text-primary hover:bg-gray-100'} icon={<div className={'pi pi-fw pi-folder text-primary'} />} text={'Browse'} onClick={handleButtonClick} />
				</div>
			</div>

			{context.itemDetails.length !== 0 && (
				<div className={styles.Item}>
					{context.itemDetails.map((item: ItemDetail, index) => (
						<div className={styles.ItemRow} key={index}>
							<ItemRowField label={'File name'} value={item.file.name} className={styles.ItemRowLarge} disabled />
							<ItemRowField label={'Quantity'} onChange={(value) => onQuantityChange(index, value)} value={'1'} className={styles.ItemRowSmall} number />
							<ItemRowField label={'Length'} value={item.length.toFixed(2)} className={styles.ItemRowSmall} disabled />
							<ItemRowField label={'Price'} value={selectedProduct?.pricePerInches.formattedPricePerUnit!} className={styles.ItemRowSmall} disabled />
							<ItemRowField label={'Total'} value={item.total.toFixed(2)} className={styles.ItemRowSmall} disabled />
						</div>
					))}
					<div className={styles.ItemRow}>
						<div className={styles.ItemRowXLarge} />
						<ItemRowField label={'Grand Total'} value={context.grandTotal.toFixed(2)} className={styles.ItemRowSmall} disabled />
					</div>
				</div>
			)}

			{/*			<div>
				<p className={'m-0 mb-2 mr-auto'}>Note:</p>
				<InputTextarea className={'w-full bg-transparent border-round-md border-solid border-gray-200'} value={''} rows={5} />
			</div>*/}
			<div className='flex flex-row justify-content-end align-items-center mt-2'>
				<PrButton className={'mr-2'} icon={<div className={'pi pi-fw pi-upload text-white ml-auto'} />} text={'Upload Files'} onClick={uploadFile} />
				<PrButton className={''} text={'Next'} disabled={!uploaded} onClick={() => onSubmit()} />
			</div>
		</div>
	);
};

export default CustomDtfOrder;
