import { SelectPrintramProductDialog } from 'components/_common/modals/select-printram-product/SelectPrintramProductDialog';
import { SideWithDesignDialog } from 'components/_common/modals/side-with-design/SideWithDesignDialog';
import { CalculateItems, CalculateOrderPricesWithVariantsAndSidesRequest } from 'features/orders/_common/calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.request';
import { CreateManuelOrderItemRequestDto, CreateManuelOrderItemSideRequestDto } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import orderService from 'features/orders/_common/order.service';
import { CoreProductSynchronizeDto } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import currencyHelper from 'helpers/curreny.helper';
import htmlToImageHelper from 'helpers/html-to-image.helper';
import stringHelper from 'helpers/string.helper';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import React, { useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { FaCirclePlus } from 'react-icons/fa6';
import { CreateOrderContext, CreateOrderContextType, StepWithComponentEnum, orderSteps } from '../../context/CreateOrderContextPrvider';
import styles from './StepProducts.module.scss';
import { ManuelOrderProductsTable } from './components/products-table/ManuelOrderProductsTable';
import { SelectPrintramProductDialogNew } from 'components/_common/modals/select-printram-product-new/SelectPrintramProductDialogNew';

export const StepProducts = () => {
	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;

	const [selectedProduct, setSelectedProduct] = useState<CoreProductSynchronizeDto | undefined>(undefined);
	const [selectProductVisible, setSelectProductVisible] = useState<boolean>(context.confirmSideWithDesigns.length < 1);
	const [sideWithDesignDialogVisible, setSideWithDesignDialogVisible] = useState<boolean>(false);

	const calculateTotal = async () => {
		try {
			if (context.request.items.length === 0) throw '';

			context.setLoading(true);

			const request = structuredClone(context.request);

			const items: CalculateItems[] = request.items.map((_product: CreateManuelOrderItemRequestDto) => ({ uniqueIdentifier: _product.uniqueId, variantId: _product.productVariantId, sideIdentitiesWithOtherFeatures: _product.sides.map((_side) => ({ productPrintSideIdentity: _side.productPrintSideId || '', productPrintSideMethodIdentity: _side.productPrintSideMethodId || '' })), quantity: _product.quantity }));

			const response = await orderService.calculateOrderPricesWithVariantsAndSidesResponse(
				new CalculateOrderPricesWithVariantsAndSidesRequest({
					shippingRate: null,
					shipTo: null,
					items: items.filter((_item) => _item.sideIdentitiesWithOtherFeatures.length > 0)
				})
			);

			if (!response.isSuccess || !response.data) throw '';

			context.setCalculatedPrice(response.data);

			context.setIsNewShippingRatesCalculateRequired(true);
		} catch (error) {
		} finally {
			context.setLoading(false);
		}
	};

	useEffect(() => {
		if (context.orderStep.value !== 1) return;

		orderSteps[0].isCanNext = context.request.items.length > 0;

		context.setOrderStep({ ...orderSteps[0] });
	}, [context.request, sideWithDesignDialogVisible]);

	useEffect(() => {
		if (!context.request.items) return;

		calculateTotal();
	}, [context.request.items]);

	return (
		<React.Fragment>
			<div className="container-body py-3 px-6">
				<ManuelOrderProductsTable />

				<div className={styles.divider} />

				<div role="button" tabIndex={0} onClick={() => setSelectProductVisible(true)} className={styles.addProduct}>
					<FaCirclePlus size="2.875rem" />
					<h5>Add Product</h5>
				</div>
			</div>

			<div className="container-body p-3 sticky bottom-0 left-0 right-0" style={{ height: '5.5rem' }}>
				<div className="flex align-items-center justify-content-end h-full gap-3">
					{context.request.items.length > 0 ? (
						<div>
							<h6 className="m-0 text-right">{currencyHelper.formatPrice(context.calculatedPrice?.fulfillment?.formattedPricePerUnit || context.calculatedPrice?.fullfillment?.formattedPricePerUnit || 1)}</h6>
							{/* <div className="text-sm m-0">+ Shipment</div> */}
						</div>
					) : null}

					<PrButton
						text="Continue"
						disabled={!context.orderStep.isCanNext}
						onClick={() => {
							if (!context.orderStep.isCanNext) return;

							for (const step of orderSteps) {
								if (step.value === StepWithComponentEnum.Products) {
									step.isCompleted = true;
									continue;
								}

								if (step.value === StepWithComponentEnum.Shipping) {
									context.setIsShowShippingForm(true);
									context.setOrderStep(step);
									continue;
								}
							}
						}}
						icon={<FaChevronRight />}
					/>
				</div>
			</div>

			<SelectPrintramProductDialogNew
				visible={selectProductVisible}
				setVisible={setSelectProductVisible}
				productId={!!selectedProduct ? undefined : context.coreProductIdFromUrl}
				handleProductClick={(_product) => {
					setSelectedProduct(_product);

					if (!sideWithDesignDialogVisible) setSideWithDesignDialogVisible(true);
				}}
			/>

			{/* <SelectPrintramProductDialog
				visible={selectProductVisible}
				setVisible={setSelectProductVisible}
				onlySelectProduct
				productId={!!selectedProduct ? undefined : context.coreProductIdFromUrl}
				handleProductClick={(_product) => {
					if (!_product) return;

					setSelectedProduct(_product);

					if (!sideWithDesignDialogVisible) setSideWithDesignDialogVisible(true);
				}}
			/> */}

			{!!selectedProduct ? (
				<SideWithDesignDialog
					visible={sideWithDesignDialogVisible}
					product={selectProductVisible ? undefined : selectedProduct}
					editProduct={undefined}
					setVisible={setSideWithDesignDialogVisible}
					onChangeProduct={() => {
						setSelectProductVisible(true);
					}}
					onConfirm={async (event) => {
						console.clear();

						context.setConfirmSideWithDesigns((_current) => [event, ..._current]);

						const request = structuredClone(context.request);
						console.log('EVENTSIDES:', event.sides);
						const newItem = new CreateManuelOrderItemRequestDto({
							uniqueId: event.uniqueId,
							productId: event.product.id,
							productVariantId: event.variant.id,
							quantity: 1,
							sides: event.sides.map(
								(_side) =>
									new CreateManuelOrderItemSideRequestDto({
										productPrintSideId: _side.id,
										productPrintSideMethodId: _side.productPrintSideMethodId,
										designFile: _side.designFile,
										templateMockupFile: htmlToImageHelper.dataUrlToFile(_side.mockupBase64!, `${stringHelper.slugify(_side.name)}-mockup.png`),
										printWidth: Math.round((_side.printInfo?.printWidth || 0) * 10) / 10,
										printHeight: Math.round((_side.printInfo?.printHeight || 0) * 10) / 10,
										printMethodAttributes: _side.printMethodAttributes
									})
							)
						});

						request.items.push(newItem);

						context.setRequest(request);

						setSelectedProduct(undefined);
					}}
				/>
			) : null}
		</React.Fragment>
	);
};
