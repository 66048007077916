import HttpBaseService from "./_common/http-base-service";

class EtsyLoggerService extends HttpBaseService{
    constructor() {
        super("requestlogger");
    }
    async sendRequestToService() : Promise<any>{
        return await this.getData("");
    }
}

export default new EtsyLoggerService();